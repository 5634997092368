import { Routes, Route } from "react-router-dom";
import {
	Landing,
	Matches,
	Players,
	Player,
	Teams,
	Team,
	Courts,
	NotFound,
	Admin,
} from "./views";
import { Footer, Header } from "./components";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

function App() {
	const theme = useSelector((state) => state.theme);
	return (
		<>
			<Helmet>
				<link
					rel="icon"
					href={
						theme === "usopen"
							? "/logo-uso.png"
							: theme === "rolandgarros"
							? "/logo-rg.png"
							: theme === "wimbledon"
							? "/logo-wbd.png"
							: "/logo-auo.png"
					}
				/>
			</Helmet>
			<div
				className={`flex flex-col justify-between h-full min-h-[100svh] text-${theme}-primaryColor`}
			>
				<section>
					<Header />
					<Routes>
						<Route path="/" element={<Landing />} />
						<Route path="/matches" element={<Matches />} />
						<Route path="/players" element={<Players />} />
						<Route path="/players/:id" element={<Player />} />
						<Route path="/teams" element={<Teams />} />
						<Route path="/teams/:id" element={<Team />} />
						<Route path="/courts" element={<Courts />} />
						<Route path="*" element={<NotFound />} />
						<Route path="/admin" element={<Admin />} />
					</Routes>
				</section>
				<Footer />
			</div>
		</>
	);
}

export default App;
