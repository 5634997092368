import { Link } from "react-router-dom";
import { TiHome } from "react-icons/ti";
import { IoPerson } from "react-icons/io5";
import { RiTeamFill } from "react-icons/ri";
import { GiTennisCourt } from "react-icons/gi";
import { IoIosTennisball } from "react-icons/io";
import { IoMenu } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logouso from "../../public/logo-uso.png";
import logorg from "../../public/logo-rg.png";
import logowbd from "../../public/logo-wbd.png";
import logoauo from "../../public/logo-auo.png";
import { useSelector } from "react-redux";

export default function Header() {
	const [showMenu, setShowMenu] = useState(false);
	const location = useLocation().pathname;
	const theme = useSelector((state) => state.theme);

	useEffect(() => {
		setShowMenu(false);
	}, [location]);

	return (
		<header className={`pt-4 max-lg:pt-2`}>
			<div
				className={`border-b-2 border-${theme}-primaryColor flex flex-row items-center max-lg:justify-between lg:flex-col lg:justify-center`}
			>
				<IoMenu
					className={`text-${theme}-primaryColor text-3xl cursor-pointer lg:hidden ml-2`}
					onClick={() => setShowMenu(!showMenu)}
				/>
				<div className={`flex flex-col items-center justify-center`}>
					<Link to="/" className={`flex flex-col items-center justify-center`}>
						<img
							src={
								theme === "usopen"
									? logouso
									: theme === "rolandgarros"
									? logorg
									: theme === "wimbledon"
									? logowbd
									: logoauo
							}
							alt="TenniStats Logo"
							className={`h-[70px] max-lg:h-[40px] max-lg:my-1 max-lg:mr-2`}
						/>

						<p
							className={`text-xs font-gothamBold mt-2 max-lg:mt-1 max-lg:hidden`}
						>
							TENNISTATS
						</p>
					</Link>
				</div>
			</div>

			{showMenu && (
				<div className={`relative`}>
					<div
						className={`flex-col justify-center items-center hidden max-lg:flex border-b-2 border-${theme}-primaryColor`}
					>
						<Link
							to="/"
							className={`text-${theme}-headerColor font-gotham font-medium text-md flex flex-row hover:bg-gray-200 w-full justify-center items-center p-3 rounded-md`}
						>
							<TiHome className={`text-${theme}-headerColor text-xl mr-2`} />
							Inicio
						</Link>
						<Link
							to="/players"
							className={`text-${theme}-headerColor font-gotham font-medium text-md flex flex-row hover:bg-gray-200 w-full justify-center items-center p-3 rounded-md`}
						>
							<IoPerson className={`text-${theme}-headerColor text-xl mr-2`} />
							Jugadores
						</Link>
						<Link
							to="/teams"
							className={`text-${theme}-headerColor font-gotham font-medium text-md flex flex-row hover:bg-gray-200 w-full justify-center items-center p-3 rounded-md`}
						>
							<RiTeamFill
								className={`text-${theme}-headerColor text-xl mr-2`}
							/>
							Equipos
						</Link>
						<Link
							to="/courts"
							className={`text-${theme}-headerColor font-gotham font-medium text-md flex flex-row hover:bg-gray-200 w-full justify-center items-center p-3 rounded-md`}
						>
							<GiTennisCourt
								className={`text-${theme}-headerColor text-xl mr-2`}
							/>
							Canchas
						</Link>
						<Link
							to="/matches"
							className={`text-${theme}-headerColor font-gotham font-medium text-md flex flex-row hover:bg-gray-200 w-full justify-center items-center p-3 rounded-md`}
						>
							<IoIosTennisball
								className={`text-${theme}-headerColor text-xl mr-2`}
							/>
							Partidos
						</Link>
					</div>
				</div>
			)}

			<div
				className={`flex justify-center items-center p-4 gap-20 max-lg:hidden`}
			>
				<Link
					to="/"
					className={`text-${theme}-headerColor font-gotham font-medium text-md flex flex-row`}
				>
					<TiHome className={`text-${theme}-headerColor text-xl mr-2`} />
					Inicio
				</Link>
				<Link
					to="/players"
					className={`text-${theme}-headerColor font-gotham font-medium text-md flex flex-row`}
				>
					<IoPerson className={`text-${theme}-headerColor text-xl mr-2`} />
					Jugadores
				</Link>
				<Link
					to="/teams"
					className={`text-${theme}-headerColor font-gotham font-medium text-md flex flex-row`}
				>
					<RiTeamFill className={`text-${theme}-headerColor text-xl mr-2`} />
					Equipos
				</Link>
				<Link
					to="/courts"
					className={`text-${theme}-headerColor font-gotham font-medium text-md flex flex-row`}
				>
					<GiTennisCourt className={`text-${theme}-headerColor text-xl mr-2`} />
					Canchas
				</Link>
				<Link
					to="/matches"
					className={`text-${theme}-headerColor font-gotham font-medium text-md flex flex-row`}
				>
					<IoIosTennisball
						className={`text-${theme}-headerColor text-xl mr-2`}
					/>
					Partidos
				</Link>
			</div>
		</header>
	);
}
