import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function TeamsRanking({ teams }) {
	const theme = useSelector((state) => state.theme);
	return (
		<div className={`w-full max-w-[800px] px-2`}>
			<p
				className={`text-center text-${theme}-primaryColor max-lg:text-base text-lg font-bold mb-2`}
			>
				Ranking de Dobles
			</p>
			{teams.length === 0 ? (
				<p className={`text-center text-${theme}-primaryColor text-sm`}>
					No hay equipos registrados.
				</p>
			) : (
				<>
					<div
						className={`overflow-hidden w-full rounded-md border border-${theme}-primaryColor`}
					>
						<table className={`w-full table-auto rounded-md`}>
							<thead>
								<tr
									className={`rounded-t-lg bg-${theme}-secondaryColor max-lg:text-xs h-8 text-${theme}-contrastColor uppercase text-sm`}
								>
									<th>Ranking</th>
									<th>Equipo</th>
									<th>Puntaje</th>
								</tr>
							</thead>
							<tbody
								className={`text-center text-md max-lg:text-sm text-${theme}-primaryColor`}
							>
								{teams
									.sort(
										(a, b) =>
											b.ranking_score - a.ranking_score ||
											b.matches_played - a.matches_played
									)
									.map((team, index) => (
										<tr
											key={team.id}
											className={"h-8" + (index !== 0 ? " border-t" : "")}
										>
											<td className={`font-bold`}>#{index + 1}</td>
											<td>
												<Link to={`/teams/${team.id}`}>
													{team?.player_1?.name}
													<br />
													{team?.player_2?.name}
												</Link>
											</td>
											<td>{(team.ranking_score * 1000).toFixed(0)}</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
					<p className={`text-center text-${theme}-primaryColor mt-2 text-xs`}>
						* El ranking se actualiza después de cada partido.
					</p>
				</>
			)}
		</div>
	);
}
