import { useEffect, useState } from "react";
import { CourtCard } from "../components";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getCourts } from "../redux/actions/actions";

export default function Courts() {
	const dispatch = useDispatch();
	const courts = useSelector((state) => state.courts);
	const [loading, setLoading] = useState(true);
	const theme = useSelector((state) => state.theme);
	useEffect(() => {
		if (courts.length === 0) dispatch(getCourts());
	}, [dispatch, courts]);

	useEffect(() => {
		if (courts.length !== 0) setLoading(false);
	}, [courts]);

	return (
		<div
			className={`flex flex-col max-lg:gap-3 gap-6 mt-2 items-center justify-center`}
		>
			{loading ? (
				<div className={`flex justify-center items-center h-[60svh]`}>
					<Loader className={`w-10`} />
				</div>
			) : (
				<div className={`w-full max-w-[800px] px-2`}>
					{courts.length === 0 ? (
						<p className={`text-center text-${theme}-primaryColor text-sm`}>
							No hay canchas registradas.
						</p>
					) : (
						<div
							className={`grid grid-cols-1 gap-4 max-lg:grid-cols-1 max-lg:gap-2`}
						>
							{courts.map((court) => (
								<div key={court.id}>
									<CourtCard court={court} />
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
}
