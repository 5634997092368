import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function NotFound() {
	const theme = useSelector((state) => state.theme);
	return (
		<div className={`flex flex-col items-center justify-center h-[60svh]`}>
			<h1 className={`text-${theme}-primaryColor font-gothamBold text-4xl`}>
				404
			</h1>
			<p className={`text-${theme}-primaryColor text-lg`}>
				Página no encontrada
			</p>
			<Link to="/" className={`text-${theme}-secondaryColor text-lg underline`}>
				Volver al inicio
			</Link>
		</div>
	);
}
