import {
	GET_PLAYERS,
	GET_TEAMS,
	GET_TEAMS_DOUBLES,
	GET_TEAMS_SINGLES,
	GET_COURTS,
	GET_MATCHES,
	CREATE_MATCH,
	CREATE_PLAYER,
	CREATE_TEAM,
	END_MATCH,
	CLEAN_STATE,
	SET_THEME,
} from "./types";
import {
	fetchPlayers,
	fetchTeams,
	fetchTeamsDoubles,
	fetchTeamsSingles,
	fetchCourts,
	fetchMatches,
	createMatch,
	createPlayer,
	createTeam,
	endMatch,
} from "../../firebase/client";

export const getPlayers = () => async (dispatch) => {
	try {
		const players = await fetchPlayers();
		dispatch({
			type: GET_PLAYERS,
			payload: players,
		});
	} catch (error) {
		console.log(error);
	}
};

export const getTeams = () => async (dispatch) => {
	try {
		const teams = await fetchTeams();
		dispatch({
			type: GET_TEAMS,
			payload: teams,
		});
	} catch (error) {
		console.log(error);
	}
};

export const getTeamsDoubles = () => async (dispatch) => {
	try {
		const teams = await fetchTeamsDoubles();
		dispatch({
			type: GET_TEAMS_DOUBLES,
			payload: teams,
		});
	} catch (error) {
		console.log(error);
	}
};

export const getTeamsSingles = () => async (dispatch) => {
	try {
		const teams = await fetchTeamsSingles();
		dispatch({
			type: GET_TEAMS_SINGLES,
			payload: teams,
		});
	} catch (error) {
		console.log(error);
	}
};

export const getCourts = () => async (dispatch) => {
	try {
		const courts = await fetchCourts();
		dispatch({
			type: GET_COURTS,
			payload: courts,
		});
	} catch (error) {
		console.log(error);
	}
};

export const getMatches = () => async (dispatch) => {
	try {
		const matches = await fetchMatches();
		dispatch({
			type: GET_MATCHES,
			payload: matches,
		});
	} catch (error) {
		console.log(error);
	}
};

export const createMatchAction = (match) => async (dispatch) => {
	try {
		const createdMatch = await createMatch(match);
		dispatch({
			type: CREATE_MATCH,
			payload: createdMatch,
		});
		return {
			error: false,
			message: "Match created successfully",
		};
	} catch (error) {
		return {
			error: true,
			message: error.response.data.message,
		};
	}
};

export const createPlayerAction = (player) => async (dispatch) => {
	try {
		await createPlayer(player);
		dispatch({
			type: CREATE_PLAYER,
			payload: player,
		});
	} catch (error) {
		console.log(error);
	}
};

export const createTeamAction = (team) => async (dispatch) => {
	try {
		await createTeam(team);
		dispatch({
			type: CREATE_TEAM,
			payload: team,
		});
	} catch (error) {
		console.log(error);
	}
};

export const endMatchAction = (match) => async (dispatch) => {
	try {
		await endMatch(match);
		dispatch({
			type: END_MATCH,
			payload: match,
		});
	} catch (error) {
		console.log(error);
	}
};

export const clearState = () => async (dispatch) => {
	dispatch({
		type: CLEAN_STATE,
	});
};

export const setTheme = (theme) => async (dispatch) => {
	localStorage.setItem("theme", theme);
	dispatch({
		type: SET_THEME,
		payload: theme,
	});
};
