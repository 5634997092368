import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function TeamCard({ team }) {
	const theme = useSelector((state) => state.theme);
	return (
		<Link
			className={`flex flex-col items-center justify-center rounded-md p-4 border-2 border-${theme}-secondaryColor h-60`}
			to={`/teams/${team?.id}`}
		>
			<div className={`flex flex-row gap-2 justify-center items-center`}>
				<img
					src={team.player_1?.avatar}
					alt={team.player_1?.name}
					className={`w-24 h-24 rounded-full object-cover border-2 border-${theme}-primaryColor`}
				/>
				<img
					src={team.player_2?.avatar}
					alt={team.player_2?.name}
					className={`w-24 h-24 rounded-full object-cover border-2 border-${theme}-primaryColor `}
				/>
			</div>
			<h1
				className={`text-${theme}-primaryColor font-bold text-base mt-2 uppercase max-lg:text-sm text-center`}
			>
				{team.player_1?.name} & {team.player_2?.name}
			</h1>
			<p
				className={`text-${theme}-primaryColor text-xs font-semibold max-lg:text-xs`}
			>
				RANKING DOBLES: #{team.ranking_position}
			</p>
		</Link>
	);
}
