import { useSelector } from "react-redux";
import MatchResult from "./MatchResult";

export default function LastMatch({ match }) {
	const theme = useSelector((state) => state.theme);
	return (
		<>
			<div className={`w-full max-w-[800px] px-2`}>
				<h1
					className={`text-center font-bold text-lg text-${theme}-primaryColor mb-2 max-lg:text-base`}
				>
					Último Partido
				</h1>
				{match ? (
					<MatchResult match={match} />
				) : (
					<p className={`text-center text-${theme}-primaryColor text-sm`}>
						No hay partidos registrados.
					</p>
				)}
			</div>
		</>
	);
}
