import { useEffect, useState } from "react";
import { PlayerCard } from "../components";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getPlayers } from "../redux/actions/actions";

export default function Players() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const players = useSelector((state) => state.players);
	const theme = useSelector((state) => state.theme);
	useEffect(() => {
		if (players.length === 0) dispatch(getPlayers());
	}, [dispatch, players]);

	useEffect(() => {
		if (players.length !== 0) setLoading(false);
	}, [players]);

	return (
		<div
			className={`flex flex-col max-lg:gap-3 gap-6 mt-2 items-center justify-center`}
		>
			{loading ? (
				<div className={`flex justify-center items-center h-[60svh]`}>
					<Loader className={`w-10`} />
				</div>
			) : (
				<div className={`w-full max-w-[800px] px-2`}>
					{players.length === 0 ? (
						<p className={`text-center text-${theme}-primaryColor text-sm`}>
							No hay jugadores registrados.
						</p>
					) : (
						<div
							className={`grid grid-cols-2 gap-4 max-lg:grid-cols-1 max-lg:gap-2`}
						>
							{players.map((player) => (
								<div key={player.id}>
									<PlayerCard player={player} />
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
}
