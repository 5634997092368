import { useDispatch, useSelector } from "react-redux";
import {
	LastMatch,
	PlayersRanking,
	TeamsRanking,
	UpcomingMatches,
	SinglesRanking,
	Loader,
} from "../components";
import { useState, useEffect } from "react";
import {
	getMatches,
	getPlayers,
	getTeamsDoubles,
	getTeamsSingles,
} from "../redux/actions/actions";

export default function Landing() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const matches = useSelector((state) => state.matches);
	const players = useSelector((state) => state.players);
	const teamsDoubles = useSelector((state) => state.teamsDoubles);
	const teamsSingles = useSelector((state) => state.teamsSingles);
	const theme = useSelector((state) => state.theme);

	useEffect(() => {
		if (matches.length === 0) dispatch(getMatches());
		if (players.length === 0) dispatch(getPlayers());
		if (teamsDoubles.length === 0) dispatch(getTeamsDoubles());
		if (teamsSingles.length === 0) dispatch(getTeamsSingles());
	}, [dispatch]);

	useEffect(() => {
		if (
			matches.length !== 0 &&
			players.length !== 0 &&
			teamsDoubles.length !== 0 &&
			teamsSingles.length !== 0
		)
			setLoading(false);
	}, [matches, players, teamsDoubles, teamsSingles]);

	return (
		<>
			{loading ? (
				<div className={`flex items-center justify-center h-[60svh]`}>
					<Loader />
				</div>
			) : (
				<div
					className={`flex flex-col max-lg:gap-3 gap-6 mt-2 items-center justify-center`}
				>
					<UpcomingMatches
						matches={matches.filter((match) => match.ended === false)}
					/>
					<LastMatch
						match={
							matches
								.filter((match) => match.ended === true)
								.sort((a, b) => b.start_date - a.start_date)
								.slice(0, 1)[0]
						}
					/>
					<PlayersRanking players={players} />
					<SinglesRanking teams={teamsSingles} />
					<TeamsRanking teams={teamsDoubles} />
				</div>
			)}
		</>
	);
}
