import {
	GET_PLAYERS,
	GET_TEAMS,
	GET_TEAMS_DOUBLES,
	GET_TEAMS_SINGLES,
	GET_COURTS,
	GET_MATCHES,
	CREATE_MATCH,
	CREATE_PLAYER,
	CREATE_TEAM,
	END_MATCH,
	CLEAN_STATE,
	SET_THEME,
} from "../actions/types";

const initialState = {
	players: [],
	teams: [],
	teamsDoubles: [],
	teamsSingles: [],
	courts: [],
	matches: [],
	endedMatches: [],
	theme: localStorage.getItem("theme") || "rolandgarros",
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GET_PLAYERS:
			return {
				...state,
				players: action.payload,
			};
		case GET_TEAMS:
			return {
				...state,
				teams: action.payload,
			};
		case GET_TEAMS_DOUBLES:
			return {
				...state,
				teamsDoubles: action.payload
					.sort(
						(a, b) =>
							b.ranking_score - a.ranking_score ||
							b.matches_played - a.matches_played
					)
					.map((team, index) => ({ ...team, ranking_position: index + 1 })),
			};
		case GET_TEAMS_SINGLES:
			return {
				...state,
				teamsSingles: action.payload,
			};
		case GET_COURTS:
			return {
				...state,
				courts: action.payload,
			};
		case GET_MATCHES:
			return {
				...state,
				matches: action.payload.sort((a, b) => b.start_date - a.start_date),
				endedMatches: action.payload.filter((match) => match.ended),
			};
		case CREATE_MATCH:
			return {
				...state,
			};
		case CREATE_PLAYER:
			return {
				...state,
			};
		case CREATE_TEAM:
			return {
				...state,
			};
		case END_MATCH:
			return {
				...state,
			};
		case CLEAN_STATE:
			return initialState;
		case SET_THEME:
			return {
				...state,
				theme: action.payload,
			};

		default:
			return state;
	}
}
