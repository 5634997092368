import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function UpcomingMatch({ match }) {
	const theme = useSelector((state) => state.theme);
	return (
		<div
			key={match.id}
			className={`flex flex-col rounded-md w-full border border-${theme}-primaryColor`}
		>
			<h2
				className={`text-center font-semibold text-sm bg-${theme}-secondaryColor text-${theme}-contrastColor py-1 px-2 rounded-t-md uppercase max-lg:text-xs`}
			>
				{match.start_date.toLocaleDateString("es-AR")}
				{" - "}
				{match.start_date.toLocaleTimeString()} -{" "}
				<a
					href={match.court.maps_link}
					target="_blank"
					className={`cursor-pointer`}
				>
					{match.court.name}, {match.court.city}
				</a>
			</h2>
			<h3
				className={`text-center px-2 py-1 text-sm font-semibold uppercase text-${theme}-primaryColor max-lg:text-xs`}
			>
				{match.category === "singles" ? (
					<>
						<Link to={`/players/${match.team_1.player_1.id}`}>
							{match.team_1.player_1.name}
							<sup className={`text-xs max-lg:text-[10px] ml-[2px]`}>
								{match.team_1.player_1.ranking_position}
							</sup>
						</Link>
					</>
				) : (
					<Link to={`/teams/${match.team_1.id}`}>
						{match.team_1.player_1.name}
						<sup className={`text-xs max-lg:text-[10px] ml-[2px]`}>
							{match.team_1.player_1.ranking_position}
						</sup>{" "}
						& {match.team_1.player_2.name}
						<sup className={`text-xs max-lg:text-[10px] ml-[2px]`}>
							{match.team_1.player_2.ranking_position}
						</sup>
					</Link>
				)}
			</h3>
			<p className={`text-center text-${theme}-primaryColor text-xs`}>VS</p>
			<h3
				className={`text-center px-2 py-1 text-sm font-semibold uppercase text-${theme}-primaryColor max-lg:text-xs`}
			>
				{match.category === "singles" ? (
					<>
						<Link to={`/players/${match.team_2.player_1.id}`}>
							{match.team_2.player_1.name}
							<sup className={`text-xs max-lg:text-[10px] ml-[2px]`}>
								{match.team_2.player_1.ranking_position}
							</sup>
						</Link>
					</>
				) : (
					<Link to={`/teams/${match.team_2.id}`}>
						{match.team_2.player_1.name}
						<sup className={`text-xs max-lg:text-[10px] ml-[2px]`}>
							{match.team_2.player_1.ranking_position}
						</sup>{" "}
						& {match.team_2.player_2.name}
						<sup className={`text-xs max-lg:text-[10px] ml-[2px]`}>
							{match.team_2.player_2.ranking_position}
						</sup>
					</Link>
				)}
			</h3>
		</div>
	);
}
