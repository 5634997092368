import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import firebaseConfig from "./firebase-config";

if (!firebase.apps?.length) {
	firebase.initializeApp(firebaseConfig);
}

const mapUserFromFirebaseAuthToUser = (user) => {
	const {
		uid,
		username,
		email,
		avatar,
		is_admin,
		starred_courts_ids,
		starred_teams_ids,
		starred_matches_ids,
	} = user;
	return {
		uid,
		username,
		email,
		avatar,
		is_admin,
		starred_courts_ids,
		starred_teams_ids,
		starred_matches_ids,
	};
};

const db = firebase.firestore();

export const onAuthStateChanged = (onChange) => {
	return firebase.auth().onAuthStateChanged((user) => {
		const uid = user?.uid;
		if (uid) {
			db.collection("users")
				.where("uid", "==", uid)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						setTimeout(() => {
							db.collection("users")
								.where("uid", "==", uid)
								.get()
								.then((querySnapshot) => {
									const newUser = querySnapshot.docs[0].data();
									const normalizeUser = user
										? mapUserFromFirebaseAuthToUser(newUser)
										: null;
									onChange(normalizeUser);
								});
						}, 1500);
					} else {
						const newUser = querySnapshot.docs[0].data();
						const normalizeUser = user
							? mapUserFromFirebaseAuthToUser(newUser)
							: null;
						onChange(normalizeUser);
					}
				});
		} else {
			onChange(null);
		}
	});
};

export const loginWithGoogle = () => {
	const googleProvider = new firebase.auth.GoogleAuthProvider();
	return firebase
		.auth()
		.signInWithPopup(googleProvider)
		.then((user) => {
			const userToDB = user.user;
			const normalizeUsername = userToDB.displayName
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "")
				.toLowerCase();
			const username = normalizeUsername.split(" ").join("");
			return db
				.collection("users")
				.where("uid", "==", userToDB.uid)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						db.collection("users")
							.where("username", "==", username)
							.get()
							.then((querySnapshotUsername) => {
								if (querySnapshotUsername.empty) {
									db.collection("users").add({
										uid: userToDB.uid,
										username,
										email: userToDB.email,
										avatar: userToDB.photoURL,
										is_admin: false,
										starred_courts_ids: [],
										starred_teams_ids: [],
										starred_matches_ids: [],
									});
								} else {
									function generateUsername() {
										const random6Digit = Math.floor(
											100000 + Math.random() * 900000
										);
										const newUsername = `${username}_${random6Digit}`;
										db.collection("users")
											.where("username", "==", newUsername)
											.get()
											.then((querySnapshotNewUsername) => {
												if (querySnapshotNewUsername.empty) {
													db.collection("users").add({
														uid: userToDB.uid,
														username: newUsername,
														email: userToDB.email,
														avatar: userToDB.photoURL,
														is_admin: false,
														starred_courts_ids: [],
														starred_teams_ids: [],
														starred_matches_ids: [],
													});
												} else {
													generateUsername();
												}
											});
									}
									generateUsername();
								}
							});
					}
				});
		});
};

export const uploadImage = (file) => {
	const randomFileName = Math.random().toString(36).substring(2);
	const ref = firebase.storage().ref(`/images/${randomFileName}`);
	const task = ref.put(file);
	return task;
};

export const logout = () => {
	return firebase.auth().signOut();
};

export const fetchPlayers = () => {
	return db
		.collection("players")
		.get()
		.then(async (querySnapshot) => {
			const teams = await db
				.collection("teams")
				.get()
				.then((querySnapshot) => {
					const teams = querySnapshot.docs.map((doc) => {
						return { ...doc.data(), id: doc.id };
					});
					return teams;
				});
			const matchesPlayed = await db
				.collection("matches")
				.get()
				.then((querySnapshot) => {
					const matches = querySnapshot.docs.map((doc) => {
						return {
							...doc.data(),
							team_1: teams.find((team) => team.id === doc.data().team_1_id),
							team_2: teams.find((team) => team.id === doc.data().team_2_id),
							team_1_id: doc.data().team_1_id,
							team_2_id: doc.data().team_2_id,
						};
					});
					return matches;
				});
			const players = querySnapshot.docs
				.sort(
					(a, b) =>
						b.data().ranking_score - a.data().ranking_score ||
						matchesPlayed.filter(
							(match) =>
								match.team_1.player_1_id === b.id ||
								match.team_1.player_2_id === b.id ||
								match.team_2.player_1_id === b.id ||
								match.team_2.player_2_id === b.id
						).length -
							matchesPlayed.filter(
								(match) =>
									match.team_1.player_1_id === a.id ||
									match.team_1.player_2_id === a.id ||
									match.team_2.player_1_id === a.id ||
									match.team_2.player_2_id === a.id
							).length
				)
				.map((doc, index) => {
					return {
						...doc.data(),
						id: doc.id,
						birth_date: doc.data().birth_date.toDate(),
						ranking_position: index + 1,
						ranking_position_singles:
							teams
								.filter((team) => team.category === "singles")
								.sort(
									(a, b) =>
										b.ranking_score - a.ranking_score ||
										matchesPlayed.filter(
											(match) =>
												match.team_1_id === b.id || match.team_2_id === b.id
										).length -
											matchesPlayed.filter(
												(match) =>
													match.team_1_id === a.id || match.team_2_id === a.id
											).length
								)
								.findIndex(
									(team) =>
										team.player_1_id === doc.id || team.player_2_id === doc.id
								) + 1,
						matches_played: matchesPlayed.filter(
							(match) =>
								match.team_1.player_1_id === doc.id ||
								match.team_1.player_2_id === doc.id ||
								match.team_2.player_1_id === doc.id ||
								match.team_2.player_2_id === doc.id
						).length,
					};
				});
			return players;
		});
};

export const fetchTeams = () => {
	return db
		.collection("teams")
		.get()
		.then(async (querySnapshot) => {
			const players = await fetchPlayers().then((data) => data);
			const teams = querySnapshot.docs.map((doc) => {
				return {
					...doc.data(),
					id: doc.id,
					player_1: players.find(
						(player) => player.id === doc.data().player_1_id
					),
					player_2:
						doc.data().player_2_id &&
						players.find((player) => player.id === doc.data().player_2_id),
				};
			});
			return teams;
		});
};

export const fetchTeamsDoubles = () => {
	return db
		.collection("teams")
		.where("category", "==", "doubles")
		.get()
		.then(async (querySnapshot) => {
			const players = await fetchPlayers().then((data) => data);
			const matchesPlayed = await db
				.collection("matches")
				.where("category", "==", "doubles")
				.get()
				.then((querySnapshot) => {
					const matches = querySnapshot.docs.map((doc) => {
						return doc.data();
					});
					return matches;
				});
			const teams = querySnapshot.docs.map((doc) => {
				return {
					...doc.data(),
					id: doc.id,
					player_1: players.find(
						(player) => player.id === doc.data().player_1_id
					),
					player_2: players.find(
						(player) => player.id === doc.data().player_2_id
					),
					matches_played: matchesPlayed.filter(
						(match) => match.team_1_id === doc.id || match.team_2_id === doc.id
					).length,
				};
			});
			return teams;
		});
};

export const fetchTeamsSingles = () => {
	return db
		.collection("teams")
		.where("category", "==", "singles")
		.get()
		.then(async (querySnapshot) => {
			const matchesPlayed = await db
				.collection("matches")
				.where("category", "==", "singles")

				.get()

				.then((querySnapshot) => {
					const matches = querySnapshot.docs.map((doc) => {
						return doc.data();
					});
					return matches;
				});

			const players = await fetchPlayers().then((data) => data);
			const teams = querySnapshot.docs.map((doc) => {
				return {
					...doc.data(),
					id: doc.id,
					player_1: players.find(
						(player) => player.id === doc.data().player_1_id
					),
					matches_played: matchesPlayed.filter(
						(match) => match.team_1_id === doc.id || match.team_2_id === doc.id
					).length,
				};
			});
			return teams;
		});
};

export const fetchCourts = () => {
	return db
		.collection("courts")
		.get()
		.then((querySnapshot) => {
			const courts = querySnapshot.docs.map((doc) => {
				return { ...doc.data(), id: doc.id };
			});
			return courts;
		});
};

export const fetchMatches = () => {
	return db
		.collection("matches")
		.get()
		.then(async (querySnapshot) => {
			const teams = await fetchTeams().then((data) => data);
			const courts = await fetchCourts().then((data) => data);
			const matches = querySnapshot.docs.map((doc) => {
				return {
					...doc.data(),
					id: doc.id,
					start_date: doc.data().start_date.toDate(),
					end_date: doc.data().end_date.toDate(),
					team_1: teams.find((team) => team.id === doc.data().team_1_id),
					team_2: teams.find((team) => team.id === doc.data().team_2_id),
					court: courts.find((court) => court.id === doc.data().court_id),
					winner_team: teams.find(
						(team) => team.id === doc.data().winner_team_id
					),
				};
			});
			return matches;
		});
};

export const createPlayer = (player) => {
	const stringToDate = new Date(player.birth_date);
	return db.collection("players").add({
		...player,
		birth_date: firebase.firestore.Timestamp.fromDate(stringToDate),
	});
};

export const createTeam = (team) => {
	return db.collection("teams").add(team);
};

export const createMatch = (match) => {
	const stringToDateStart = new Date(match.start_date);
	const stringToDateEnd = new Date(match.end_date);

	return db.collection("matches").add({
		...match,
		start_date: firebase.firestore.Timestamp.fromDate(stringToDateStart),
		end_date: firebase.firestore.Timestamp.fromDate(stringToDateEnd),
		winner_team_id: null,
		ended: false,
		scores: {
			set_1: {
				games_team_1: 0,
				games_team_2: 0,
				tiebreak: false,
				tiebreak_team_1: 0,
				tiebreak_team_2: 0,
			},
			set_2: {
				games_team_1: 0,
				games_team_2: 0,
				tiebreak: false,
				tiebreak_team_1: 0,
				tiebreak_team_2: 0,
			},
			set_3: {
				games_team_1: 0,
				games_team_2: 0,
				tiebreak: false,
				tiebreak_team_1: 0,
				tiebreak_team_2: 0,
			},
			set_4: {
				games_team_1: 0,
				games_team_2: 0,
				tiebreak: false,
				tiebreak_team_1: 0,
				tiebreak_team_2: 0,
			},
			set_5: {
				games_team_1: 0,
				games_team_2: 0,
				tiebreak: false,
				tiebreak_team_1: 0,
				tiebreak_team_2: 0,
			},
		},
	});
};

export const endMatch = async (match) => {
	await db
		.collection("matches")
		.doc(match.id)
		.update({
			ended: true,
			winner_team_id: match.winner_team_id,
			scores: {
				set_1: {
					games_team_1: Number(match.scores.set_1.games_team_1),
					games_team_2: Number(match.scores.set_1.games_team_2),
					tiebreak: match.scores.set_1.tiebreak,
					tiebreak_team_1: Number(match.scores.set_1.tiebreak_team_1),
					tiebreak_team_2: Number(match.scores.set_1.tiebreak_team_2),
				},
				set_2: {
					games_team_1: Number(match.scores.set_2.games_team_1),
					games_team_2: Number(match.scores.set_2.games_team_2),
					tiebreak: match.scores.set_2.tiebreak,
					tiebreak_team_1: Number(match.scores.set_2.tiebreak_team_1),
					tiebreak_team_2: Number(match.scores.set_2.tiebreak_team_2),
				},
				set_3: {
					games_team_1: Number(match.scores.set_3.games_team_1),
					games_team_2: Number(match.scores.set_3.games_team_2),
					tiebreak: match.scores.set_3.tiebreak,
					tiebreak_team_1: Number(match.scores.set_3.tiebreak_team_1),
					tiebreak_team_2: Number(match.scores.set_3.tiebreak_team_2),
				},
				set_4: {
					games_team_1: Number(match.scores.set_4.games_team_1),
					games_team_2: Number(match.scores.set_4.games_team_2),
					tiebreak: match.scores.set_4.tiebreak,
					tiebreak_team_1: Number(match.scores.set_4.tiebreak_team_1),
					tiebreak_team_2: Number(match.scores.set_4.tiebreak_team_2),
				},
				set_5: {
					games_team_1: Number(match.scores.set_5.games_team_1),
					games_team_2: Number(match.scores.set_5.games_team_2),
					tiebreak: match.scores.set_5.tiebreak,
					tiebreak_team_1: Number(match.scores.set_5.tiebreak_team_1),
					tiebreak_team_2: Number(match.scores.set_5.tiebreak_team_2),
				},
			},
		});

	// update ranking score. it is calculated as (matches won/total matches) * sqrt(total matches)
	const matches = await fetchMatches().then((data) =>
		data.filter((m) => m.ended)
	);

	if (matches.find((m) => m.id === match.id).category === "doubles") {
		const matchesPlayedTeam1 = matches.filter(
			(m) => m.team_1_id === match.team_1_id || m.team_2_id === match.team_1_id
		).length;
		const matchesWonTeam1 = matches.filter(
			(m) =>
				(m.team_1_id === match.team_1_id || m.team_2_id === match.team_1_id) &&
				m.winner_team_id === match.team_1_id
		).length;

		const matchesPlayedTeam2 = matches.filter(
			(m) => m.team_1_id === match.team_2_id || m.team_2_id === match.team_2_id
		).length;

		const matchesWonTeam2 = matches.filter(
			(m) =>
				(m.team_1_id === match.team_2_id || m.team_2_id === match.team_2_id) &&
				m.winner_team_id === match.team_2_id
		).length;

		const matchesPlayedPlayer1Team1 = matches.filter(
			(m) =>
				m.team_1.player_1_id === match.team_1.player_1_id ||
				m.team_1.player_2_id === match.team_1.player_1_id ||
				m.team_2.player_1_id === match.team_1.player_1_id ||
				m.team_2.player_2_id === match.team_1.player_1_id
		).length;

		const matchesWonPlayer1Team1 = matches.filter(
			(m) =>
				(m.team_1.player_1_id === match.team_1.player_1_id ||
					m.team_1.player_2_id === match.team_1.player_1_id ||
					m.team_2.player_1_id === match.team_1.player_1_id ||
					m.team_2.player_2_id === match.team_1.player_1_id) &&
				(m.winner_team.player_1_id === match.team_1.player_1_id ||
					m.winner_team.player_2_id === match.team_1.player_1_id)
		).length;

		const matchesPlayedPlayer2Team1 = matches.filter(
			(m) =>
				m.team_1.player_1_id === match.team_1.player_2_id ||
				m.team_1.player_2_id === match.team_1.player_2_id ||
				m.team_2.player_1_id === match.team_1.player_2_id ||
				m.team_2.player_2_id === match.team_1.player_2_id
		).length;

		const matchesWonPlayer2Team1 = matches.filter(
			(m) =>
				(m.team_1.player_1_id === match.team_1.player_2_id ||
					m.team_1.player_2_id === match.team_1.player_2_id ||
					m.team_2.player_1_id === match.team_1.player_2_id ||
					m.team_2.player_2_id === match.team_1.player_2_id) &&
				(m.winner_team.player_1_id === match.team_1.player_2_id ||
					m.winner_team.player_2_id === match.team_1.player_2_id)
		).length;

		const matchesPlayedPlayer1Team2 = matches.filter(
			(m) =>
				m.team_1.player_1_id === match.team_2.player_1_id ||
				m.team_1.player_2_id === match.team_2.player_1_id ||
				m.team_2.player_1_id === match.team_2.player_1_id ||
				m.team_2.player_2_id === match.team_2.player_1_id
		).length;

		const matchesWonPlayer1Team2 = matches.filter(
			(m) =>
				(m.team_1.player_1_id === match.team_2.player_1_id ||
					m.team_1.player_2_id === match.team_2.player_1_id ||
					m.team_2.player_1_id === match.team_2.player_1_id ||
					m.team_2.player_2_id === match.team_2.player_1_id) &&
				(m.winner_team.player_1_id === match.team_2.player_1_id ||
					m.winner_team.player_2_id === match.team_2.player_1_id)
		).length;

		const matchesPlayedPlayer2Team2 = matches.filter(
			(m) =>
				m.team_1.player_1_id === match.team_2.player_2_id ||
				m.team_1.player_2_id === match.team_2.player_2_id ||
				m.team_2.player_1_id === match.team_2.player_2_id ||
				m.team_2.player_2_id === match.team_2.player_2_id
		).length;

		const matchesWonPlayer2Team2 = matches.filter(
			(m) =>
				(m.team_1.player_1_id === match.team_2.player_2_id ||
					m.team_1.player_2_id === match.team_2.player_2_id ||
					m.team_2.player_1_id === match.team_2.player_2_id ||
					m.team_2.player_2_id === match.team_2.player_2_id) &&
				(m.winner_team.player_1_id === match.team_2.player_2_id ||
					m.winner_team.player_2_id === match.team_2.player_2_id)
		).length;

		const rankingScorePlayer1Team1 =
			(matchesWonPlayer1Team1 / matchesPlayedPlayer1Team1) *
			Math.sqrt(matchesPlayedPlayer1Team1);
		const rankingScorePlayer2Team1 =
			(matchesWonPlayer2Team1 / matchesPlayedPlayer2Team1) *
			Math.sqrt(matchesPlayedPlayer2Team1);
		const rankingScorePlayer1Team2 =
			(matchesWonPlayer1Team2 / matchesPlayedPlayer1Team2) *
			Math.sqrt(matchesPlayedPlayer1Team2);
		const rankingScorePlayer2Team2 =
			(matchesWonPlayer2Team2 / matchesPlayedPlayer2Team2) *
			Math.sqrt(matchesPlayedPlayer2Team2);
		const rankingScoreTeam1 =
			(matchesWonTeam1 / matchesPlayedTeam1) * Math.sqrt(matchesPlayedTeam1);
		const rankingScoreTeam2 =
			(matchesWonTeam2 / matchesPlayedTeam2) * Math.sqrt(matchesPlayedTeam2);

		await db.collection("players").doc(match.team_1.player_1_id).update({
			ranking_score: rankingScorePlayer1Team1,
		});
		await db.collection("players").doc(match.team_1.player_2_id).update({
			ranking_score: rankingScorePlayer2Team1,
		});
		await db.collection("players").doc(match.team_2.player_1_id).update({
			ranking_score: rankingScorePlayer1Team2,
		});
		await db.collection("players").doc(match.team_2.player_2_id).update({
			ranking_score: rankingScorePlayer2Team2,
		});
		await db.collection("teams").doc(match.team_1_id).update({
			ranking_score: rankingScoreTeam1,
		});
		await db.collection("teams").doc(match.team_2_id).update({
			ranking_score: rankingScoreTeam2,
		});

		return;
	} else {
		const matchesPlayedTeam1 = matches.filter(
			(m) => m.team_1_id === match.team_1_id || m.team_2_id === match.team_1_id
		).length;
		const matchesWonTeam1 = matches.filter(
			(m) =>
				(m.team_1_id === match.team_1_id || m.team_2_id === match.team_1_id) &&
				m.winner_team_id === match.team_1_id
		).length;

		const matchesPlayedTeam2 = matches.filter(
			(m) => m.team_1_id === match.team_2_id || m.team_2_id === match.team_2_id
		).length;

		const matchesWonTeam2 = matches.filter(
			(m) =>
				(m.team_1_id === match.team_2_id || m.team_2_id === match.team_2_id) &&
				m.winner_team_id === match.team_2_id
		).length;

		const matchesPlayedPlayer1Team1 = matches.filter(
			(m) =>
				m.team_1.player_1_id === match.team_1.player_1_id ||
				m.team_2.player_1_id === match.team_1.player_1_id ||
				m.team_1.player_2_id === match.team_1.player_1_id ||
				m.team_2.player_2_id === match.team_1.player_1_id
		).length;

		const matchesWonPlayer1Team1 = matches.filter(
			(m) =>
				(m.team_1.player_1_id === match.team_1.player_1_id ||
					m.team_2.player_1_id === match.team_1.player_1_id ||
					m.team_1.player_2_id === match.team_1.player_1_id ||
					m.team_2.player_2_id === match.team_1.player_1_id) &&
				(m.winner_team.player_1_id === match.team_1.player_1_id ||
					m.winner_team.player_2_id === match.team_1.player_1_id)
		).length;

		const matchesPlayedPlayer1Team2 = matches.filter(
			(m) =>
				m.team_1.player_1_id === match.team_2.player_1_id ||
				m.team_2.player_1_id === match.team_2.player_1_id ||
				m.team_1.player_2_id === match.team_2.player_1_id ||
				m.team_2.player_2_id === match.team_2.player_1_id
		).length;

		const matchesWonPlayer1Team2 = matches.filter(
			(m) =>
				(m.team_1.player_1_id === match.team_2.player_1_id ||
					m.team_2.player_1_id === match.team_2.player_1_id ||
					m.team_1.player_2_id === match.team_2.player_1_id ||
					m.team_2.player_2_id === match.team_2.player_1_id) &&
				(m.winner_team.player_1_id === match.team_2.player_1_id ||
					m.winner_team.player_2_id === match.team_2.player_1_id)
		).length;

		const rankingScorePlayer1Team1 =
			(matchesWonPlayer1Team1 / matchesPlayedPlayer1Team1) *
			Math.sqrt(matchesPlayedPlayer1Team1);
		const rankingScorePlayer1Team2 =
			(matchesWonPlayer1Team2 / matchesPlayedPlayer1Team2) *
			Math.sqrt(matchesPlayedPlayer1Team2);
		const rankingScoreTeam1 =
			(matchesWonTeam1 / matchesPlayedTeam1) * Math.sqrt(matchesPlayedTeam1);
		const rankingScoreTeam2 =
			(matchesWonTeam2 / matchesPlayedTeam2) * Math.sqrt(matchesPlayedTeam2);

		await db.collection("players").doc(match.team_1.player_1_id).update({
			ranking_score: rankingScorePlayer1Team1,
		});
		await db.collection("players").doc(match.team_2.player_1_id).update({
			ranking_score: rankingScorePlayer1Team2,
		});
		await db.collection("teams").doc(match.team_1_id).update({
			ranking_score: rankingScoreTeam1,
		});
		await db.collection("teams").doc(match.team_2_id).update({
			ranking_score: rankingScoreTeam2,
		});

		return;
	}
};

export const createCourt = (court) => {
	return db.collection("courts").add(court);
};
