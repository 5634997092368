import { useEffect, useState } from "react";
import useUser from "../hooks/useUser";
import {
	CreateCourt,
	CreatePlayer,
	CreateTeam,
	EndMatch,
	Loader,
} from "../components";
import { loginWithGoogle, logout } from "../firebase/client";
import { useDispatch, useSelector } from "react-redux";
import {
	getMatches,
	getCourts,
	getTeams,
	getPlayers,
	createMatchAction,
} from "../redux/actions/actions";

export default function Admin() {
	const dispatch = useDispatch();
	const user = useUser();
	const [loading, setLoading] = useState(true);
	const courts = useSelector((state) => state.courts);
	const matches = useSelector((state) => state.matches);
	const players = useSelector((state) => state.players);
	const theme = useSelector((state) => state.theme);
	const [createMatchData, setCreateMatchData] = useState({
		startTime: "",
		endTime: "",
		court: "",
		category: "singles",
		sets: 3,
		team1: "",
		team2: "",
		duration: 0,
	});

	const [submittingCreateMatch, setSubmittingCreateMatch] = useState(false);

	const teams = useSelector((state) => state.teams);

	useEffect(() => {
		if (user !== undefined) {
			setLoading(false);
		}
		if (user && user.is_admin) {
			if (courts.length === 0) dispatch(getCourts());
			if (matches.length === 0) dispatch(getMatches());
			if (teams.length === 0) dispatch(getTeams());
			if (players.length === 0) dispatch(getPlayers());
			setLoading(true);
		}
	}, [user]);

	useEffect(() => {
		if (
			user &&
			user.is_admin &&
			courts.length > 0 &&
			matches.length > 0 &&
			teams.length > 0 &&
			players.length > 0
		) {
			setLoading(false);
		}
	}, [user, courts, matches, teams, players]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (submittingCreateMatch) return;
		setSubmittingCreateMatch(true);
		if (
			createMatchData.startTime === "" ||
			createMatchData.endTime === "" ||
			createMatchData.court === "" ||
			createMatchData.category === "" ||
			createMatchData.sets === 0 ||
			createMatchData.team1 === "" ||
			createMatchData.team2 === "" ||
			createMatchData.duration === 0
		) {
			alert("Completa todos los campos.");
			setSubmittingCreateMatch(false);
			return;
		}
		if (createMatchData.team1 === createMatchData.team2) {
			alert("Los equipos no pueden ser iguales.");
			setSubmittingCreateMatch(false);
			return;
		}
		if (createMatchData.startTime >= createMatchData.endTime) {
			alert("La fecha de inicio debe ser anterior a la fecha de fin.");
			setSubmittingCreateMatch(false);
			return;
		}
		if (
			createMatchData.startTime &&
			createMatchData.endTime &&
			createMatchData.court &&
			createMatchData.category &&
			createMatchData.sets &&
			createMatchData.team1 &&
			createMatchData.team2 &&
			createMatchData.duration
		) {
			dispatch(
				createMatchAction({
					start_date: createMatchData.startTime,
					end_date: createMatchData.endTime,
					court_id: createMatchData.court,
					category: createMatchData.category,
					sets: Number(createMatchData.sets),
					team_1_id: createMatchData.team1,
					team_2_id: createMatchData.team2,
					duration: Number(createMatchData.duration),
				})
			).then((res) => {
				if (res?.error) {
					alert("Error al crear el partido.");
					setSubmittingCreateMatch(false);
					return;
				} else {
					alert("Partido creado correctamente.");
					setCreateMatchData({
						startTime: "",
						endTime: "",
						court: "",
						category: "singles",
						sets: 3,
						team1: "",
						team2: "",
						duration: 0,
					});
					setSubmittingCreateMatch(false);
					window.location.reload();
				}
			});
		}
	};

	return (
		<div
			className={`flex flex-col max-lg:gap-3 gap-6 mt-2 items-center justify-center`}
		>
			{loading ? (
				<div className={`flex justify-center items-center h-[60svh]`}>
					<Loader className={`w-10`} />
				</div>
			) : (
				<>
					{user === null && (
						<div
							className={`w-full max-w-[800px] px-2 h-[60svh] flex flex-col items-center justify-center`}
						>
							<h1
								className={`text-center text-xl text-${theme}-primaryColor font-gothamBold`}
							>
								PANEL DE ADMINISTRADOR
							</h1>
							<p className={`text-center text-${theme}-primaryColor text-sm`}>
								Para acceder al panel de administrador, inicia sesión con una
								cuenta de Google autorizada.
							</p>
							<button
								onClick={loginWithGoogle}
								className={`bg-${theme}-primaryColor text-white text-sm font-gothamBold px-4 py-2 rounded-md mt-4`}
							>
								Iniciar sesión
							</button>
						</div>
					)}
					{user && (
						<>
							{user.is_admin ? (
								<div
									className={`w-full max-w-[800px] px-2 flex flex-col items-center`}
								>
									<h1
										className={`text-center text-lg text-${theme}-primaryColor font-gothamBold`}
									>
										Bienvenido, {user.username}!
									</h1>
									<p
										onClick={logout}
										className={` text-${theme}-primaryColor underline text-sm rounded-md`}
									>
										Cerrar sesión
									</p>

									<div className={`w-full flex-col flex items-start`}>
										<h2
											className={`text-${theme}-primaryColor text-lg font-gothamBold mt-2`}
										>
											Crear partido:
										</h2>
										<form className={`w-full flex flex-col items-start`}>
											<label
												htmlFor="matchStartTime"
												className={`text-${theme}-primaryColor text-sm font-gothamBold mt-2`}
											>
												Fecha de inicio:
											</label>
											<input
												type="datetime-local"
												id="matchStartTime"
												className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
												onChange={(e) =>
													setCreateMatchData({
														...createMatchData,
														startTime: e.target.value,
													})
												}
												value={createMatchData.startTime}
											/>

											<label
												htmlFor="matchEndTime"
												className={`text-${theme}-primaryColor text-sm font-gothamBold mt-2`}
											>
												Fecha de fin:
											</label>
											<input
												type="datetime-local"
												id="matchEndTime"
												className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
												onChange={(e) =>
													setCreateMatchData({
														...createMatchData,
														endTime: e.target.value,
													})
												}
												value={createMatchData.endTime}
											/>

											<label
												htmlFor="matchDuration"
												className={`text-${theme}-primaryColor text-sm font-gothamBold mt-2`}
											>
												Duración:
											</label>
											<input
												type="number"
												id="matchDuration"
												className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
												onChange={(e) =>
													setCreateMatchData({
														...createMatchData,
														duration: e.target.value,
													})
												}
												value={createMatchData.duration}
											/>

											<label
												htmlFor="matchCourt"
												className={`text-${theme}-primaryColor text-sm font-gothamBold mt-2`}
											>
												Cancha:
											</label>
											<select
												id="matchCourt"
												className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1`}
												onChange={(e) =>
													setCreateMatchData({
														...createMatchData,
														court: e.target.value,
													})
												}
											>
												<option
													value=""
													disabled
													selected={createMatchData.court === ""}
												>
													Selecciona una cancha
												</option>
												{courts.map((court) => (
													<option
														key={court.id}
														value={court.id}
														selected={createMatchData.court === court.id}
													>
														{court.name}
													</option>
												))}
											</select>

											<label
												htmlFor="category"
												className={`text-${theme}-primaryColor text-sm font-gothamBold mt-2`}
											>
												Categoría:
											</label>

											<select
												id="category"
												className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1`}
												onChange={(e) =>
													setCreateMatchData({
														...createMatchData,
														category: e.target.value,
														team1: "",
														team2: "",
													})
												}
											>
												<option
													value="singles"
													selected={createMatchData.category === "singles"}
												>
													Singles
												</option>
												<option
													value="doubles"
													selected={createMatchData.category === "doubles"}
												>
													Dobles
												</option>
											</select>

											<label
												htmlFor="sets"
												className={`text-${theme}-primaryColor text-sm font-gothamBold mt-2`}
											>
												Sets:
											</label>
											<select
												id="sets"
												className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1`}
												onChange={(e) =>
													setCreateMatchData({
														...createMatchData,
														sets: e.target.value,
													})
												}
											>
												<option value={3} selected={createMatchData.sets === 3}>
													3
												</option>
												<option value={5} selected={createMatchData.sets === 5}>
													5
												</option>
											</select>

											<label
												htmlFor="matchTeam1"
												className={`text-${theme}-primaryColor text-sm font-gothamBold mt-2`}
											>
												Equipo 1:
											</label>
											<select
												id="matchTeam1"
												className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1`}
												onChange={(e) =>
													setCreateMatchData({
														...createMatchData,
														team1: e.target.value,
													})
												}
											>
												<option
													value=""
													disabled
													selected={createMatchData.team1 === ""}
												>
													Selecciona un equipo
												</option>
												{teams
													.filter(
														(team) => team.category === createMatchData.category
													)
													.filter((team) =>
														createMatchData.category === "doubles"
															? team.player_1?.id !==
																	teams.find(
																		(team) => team.id === createMatchData.team2
																	)?.player_1?.id &&
															  team.player_1?.id !==
																	teams.find(
																		(team) => team.id === createMatchData.team2
																	)?.player_2?.id &&
															  team.player_2?.id !==
																	teams.find(
																		(team) => team.id === createMatchData.team2
																	)?.player_1?.id &&
															  team.player_2?.id !==
																	teams.find(
																		(team) => team.id === createMatchData.team2
																	)?.player_2?.id
															: team.player_1?.id !==
															  teams.find(
																	(team) => team.id === createMatchData.team2
															  )?.player_1?.id
													)
													.map((team) => (
														<option key={team.id} value={team?.id}>
															{team.player_1.name}{" "}
															{createMatchData.category === "doubles" &&
																`& ${team.player_2.name}`}
														</option>
													))}
											</select>

											<label
												htmlFor="matchTeam2"
												className={`text-${theme}-primaryColor text-sm font-gothamBold mt-2`}
											>
												Equipo 2:
											</label>
											<select
												id="matchTeam2"
												className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1`}
												onChange={(e) =>
													setCreateMatchData({
														...createMatchData,
														team2: e.target.value,
													})
												}
											>
												<option
													value=""
													disabled
													selected={createMatchData.team2 === ""}
												>
													Selecciona un equipo
												</option>
												{teams
													.filter(
														(team) => team.category === createMatchData.category
													)
													.filter((team) =>
														createMatchData.category === "doubles"
															? team.player_1?.id !==
																	teams.find(
																		(team) => team.id === createMatchData.team1
																	)?.player_1?.id &&
															  team.player_1?.id !==
																	teams.find(
																		(team) => team.id === createMatchData.team1
																	)?.player_2?.id &&
															  team.player_2?.id !==
																	teams.find(
																		(team) => team.id === createMatchData.team1
																	)?.player_1?.id &&
															  team.player_2?.id !==
																	teams.find(
																		(team) => team.id === createMatchData.team1
																	)?.player_2?.id
															: team.player_1?.id !==
															  teams.find(
																	(team) => team.id === createMatchData.team1
															  )?.player_1?.id
													)
													.map((team) => (
														<option key={team.id} value={team?.id}>
															{team.player_1.name}{" "}
															{createMatchData.category === "doubles" &&
																`& ${team.player_2.name}`}
														</option>
													))}
											</select>

											<button
												className={`w-full bg-${theme}-primaryColor text-white text-sm font-gothamBold px-4 py-2 rounded-md mt-4`}
												onClick={handleSubmit}
												type="submit"
												disabled={submittingCreateMatch}
											>
												{submittingCreateMatch
													? "Creando partido..."
													: "Crear partido"}
											</button>
										</form>

										<hr
											className={`w-full border border-${theme}-primaryColor mt-4`}
										/>
										<h2
											className={`text-${theme}-primaryColor text-lg font-gothamBold mt-2`}
										>
											Finalizar partido:
										</h2>
										{matches.filter((match) => match.ended === false).length >
										0 ? (
											<div
												className={`w-full flex flex-col items-center justify-center gap-2`}
											>
												{matches
													.filter((match) => match.ended === false)
													.map((match) => (
														<div key={match.id} className={`w-full`}>
															<EndMatch match={match} />
														</div>
													))}
											</div>
										) : (
											<p
												className={`text-center text-${theme}-primaryColor text-sm`}
											>
												No hay partidos programados.
											</p>
										)}
										<hr
											className={`w-full border border-${theme}-primaryColor mt-4`}
										/>
										<h2
											className={`text-${theme}-primaryColor text-lg font-gothamBold mt-2`}
										>
											Crear jugador:
										</h2>
										<CreatePlayer />

										<hr
											className={`w-full border border-${theme}-primaryColor mt-4`}
										/>
										<h2
											className={`text-${theme}-primaryColor text-lg font-gothamBold mt-2`}
										>
											Crear equipo:
										</h2>
										<CreateTeam teams={teams} players={players} />
										<hr
											className={`w-full border border-${theme}-primaryColor mt-4`}
										/>
										<h2
											className={`text-${theme}-primaryColor text-lg font-gothamBold mt-2`}
										>
											Crear cancha:
										</h2>
										<CreateCourt />
									</div>
								</div>
							) : (
								<div
									className={`w-full max-w-[800px] px-2 h-[60svh] flex flex-col items-center justify-center`}
								>
									<h1
										className={`text-center text-xl text-${theme}-primaryColor font-gothamBold`}
									>
										PANEL DE ADMINISTRADOR
									</h1>
									<p
										className={`text-center text-${theme}-primaryColor text-sm`}
									>
										Tu cuenta no tiene permisos de administrador.
									</p>
									<button
										onClick={logout}
										className={`bg-${theme}-primaryColor text-white text-sm font-gothamBold px-4 py-2 rounded-md mt-4`}
									>
										Cerrar sesión
									</button>
								</div>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
}
