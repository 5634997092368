import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";

export default function MatchResult({ match }) {
	const theme = useSelector((state) => state.theme);
	return (
		<>
			<div
				className={`flex flex-col rounded-md w-full border border-${theme}-primaryColor`}
			>
				<h2
					className={`text-center font-semibold text-sm bg-${theme}-secondaryColor text-${theme}-contrastColor rounded-t-[5px] uppercase max-lg:text-xs px-2 py-2`}
				>
					{match?.start_date.toLocaleDateString("es-AR")} -{" "}
					{match?.start_date.toLocaleTimeString()} -{" "}
					<a
						href={match?.court.maps_link}
						target="_blank"
						className={`cursor-pointer`}
					>
						{match?.court.name}, {match?.court.city}
					</a>
				</h2>
				<div className={`flex flex-col`}>
					<div
						className={`flex flex-row justify-between items-center px-2 py-2`}
					>
						<div>
							<h3
								className={`text-start text-sm font-semibold uppercase text-${theme}-primaryColor flex flex-row items-center max-lg:text-xs`}
							>
								{match?.category === "singles" ? (
									<>
										<Link to={`/players/${match?.team_1.player_1.id}`}>
											{match?.team_1.player_1.name}
											<sup className={`text-xs max-lg:text-[10px] ml-[2px]`}>
												{match?.team_1.player_1.ranking_position}
											</sup>
										</Link>
									</>
								) : (
									<Link
										className={`flex flex-col gap-1`}
										to={`/teams/${match?.team_1.id}`}
									>
										<div>
											{match?.team_1.player_1.name}
											<sup className={`text-xs max-lg:text-[10px] ml-[2px]`}>
												{match?.team_1.player_1.ranking_position}
											</sup>
										</div>

										<div>
											{match?.team_1.player_2.name}
											<sup className={`text-xs max-lg:text-[10px] ml-[2px]`}>
												{match?.team_1.player_2.ranking_position}
											</sup>
										</div>
									</Link>
								)}
								{match?.winner_team_id === match?.team_1.id && (
									<FaCheck
										className={`text-${theme}-primaryColor text-sm ml-2 max-lg:text-xs`}
									/>
								)}
							</h3>
						</div>
						<div
							className={
								"grid " +
								(match?.sets === 5
									? "grid-cols-5 gap-6"
									: "grid-cols-3 gap-8") +
								(match?.scores.set_3.tiebreak || match?.scores.set_5.tiebreak
									? " mr-4"
									: " mr-2")
							}
						>
							<div>
								<div
									className={
										`text-${theme}-primaryColor text-base max-lg:text-base flex flex-row ` +
										(match?.scores.set_1.games_team_1 >
										match?.scores.set_1.games_team_2
											? "font-bold"
											: "")
									}
								>
									{match?.scores.set_1.games_team_1}
									{match?.scores.set_1.tiebreak && (
										<p className={`relative`}>
											<sup className={`absolute text-xs top-0`}>
												({match?.scores.set_1.tiebreak_team_1})
											</sup>
										</p>
									)}
								</div>
							</div>
							<div>
								<div
									className={
										`text-${theme}-primaryColor text-base max-lg:text-base flex flex-row ` +
										(match?.scores.set_2.games_team_1 >
										match?.scores.set_2.games_team_2
											? "font-bold"
											: "")
									}
								>
									{match?.scores.set_2.games_team_1}
									{match?.scores.set_2.tiebreak && (
										<p className={`relative`}>
											<sup className={`absolute text-xs top-0`}>
												({match?.scores.set_2.tiebreak_team_1})
											</sup>
										</p>
									)}
								</div>
							</div>
							<div>
								<div
									className={
										`text-${theme}-primaryColor text-base max-lg:text-base flex flex-row ` +
										(match?.sets === 3 &&
										match?.scores.set_3.games_team_1 === 0 &&
										match?.scores.set_3.games_team_2 === 0
											? ""
											: match?.scores.set_3.games_team_1 >
											  match?.scores.set_3.games_team_2
											? "font-bold"
											: "")
									}
								>
									{match?.sets === 3 &&
									match?.scores.set_3.games_team_1 === 0 &&
									match?.scores.set_3.games_team_2 === 0
										? "-"
										: match?.scores.set_3.games_team_1}
									{match?.scores.set_3.tiebreak && (
										<p className={`relative`}>
											<sup className={`absolute text-xs top-0`}>
												({match?.scores.set_3.tiebreak_team_1})
											</sup>
										</p>
									)}
								</div>
							</div>
							{match?.sets === 5 && (
								<>
									<div>
										<div
											className={
												`text-${theme}-primaryColor text-base max-lg:text-base flex flex-row ` +
												(match?.scores.set_4.games_team_1 >
												match?.scores.set_4.games_team_2
													? "font-bold"
													: "")
											}
										>
											{match?.scores.set_4.games_team_1 === 0 &&
											match?.scores.set_4.games_team_2 === 0
												? "-"
												: match?.scores.set_4.games_team_1}
											{match?.scores.set_4.tiebreak && (
												<p className={`relative`}>
													<sup className={`absolute text-xs top-0`}>
														({match?.scores.set_4.tiebreak_team_1})
													</sup>
												</p>
											)}
										</div>
									</div>
									<div>
										<div
											className={
												`text-${theme}-primaryColor text-base max-lg:text-base flex flex-row ` +
												(match?.scores.set_5.games_team_1 >
												match?.scores.set_5.games_team_2
													? "font-bold"
													: "")
											}
										>
											{match?.scores.set_5.games_team_1 === 0 &&
											match?.scores.set_5.games_team_2 === 0
												? "-"
												: match?.scores.set_5.games_team_1}
											{match?.scores.set_5.tiebreak && (
												<p className={`relative`}>
													<sup className={`absolute text-xs top-0`}>
														({match?.scores.set_5.tiebreak_team_1})
													</sup>
												</p>
											)}
										</div>
									</div>
								</>
							)}
						</div>
					</div>
					<div className={`flex flex-row justify-between items-center`}>
						<hr className={`border-gray-200 w-full`} />
					</div>
					<div
						className={`flex flex-row justify-between items-center px-2 py-2`}
					>
						<div>
							<h3
								className={`text-start   text-sm font-semibold uppercase text-${theme}-primaryColor max-lg:text-xs flex flex-row items-center`}
							>
								{match?.category === "singles" ? (
									<>
										<Link to={`/players/${match?.team_2.player_1.id}`}>
											{match?.team_2.player_1.name}
											<sup className={`text-xs max-lg:text-[10px] ml-[2px]`}>
												{match?.team_2.player_1.ranking_position}
											</sup>
										</Link>
									</>
								) : (
									<Link
										className={`flex flex-col gap-1`}
										to={`/teams/${match?.team_2.id}`}
									>
										<div>
											{match?.team_2.player_1.name}
											<sup className={`text-xs max-lg:text-[10px] ml-[2px]`}>
												{match?.team_2.player_1.ranking_position}
											</sup>
										</div>

										<div>
											{match?.team_2.player_2.name}
											<sup className={`text-xs max-lg:text-[10px] ml-[2px]`}>
												{match?.team_2.player_2.ranking_position}
											</sup>
										</div>
									</Link>
								)}
								{match?.winner_team_id === match?.team_2.id && (
									<FaCheck
										className={`text-${theme}-primaryColor text-sm ml-2 max-lg:text-xs`}
									/>
								)}
							</h3>
						</div>
						<div
							className={
								"grid " +
								(match?.sets === 5
									? "grid-cols-5 gap-6"
									: "grid-cols-3 gap-8") +
								(match?.scores.set_3.tiebreak || match?.scores.set_5.tiebreak
									? " mr-4"
									: " mr-2")
							}
						>
							<div>
								<div
									className={
										`text-${theme}-primaryColor text-base max-lg:text-base flex flex-row ` +
										(match?.scores.set_1.games_team_2 >
										match?.scores.set_1.games_team_1
											? "font-bold"
											: "")
									}
								>
									{match?.scores.set_1.games_team_2}
									{match?.scores.set_1.tiebreak && (
										<p className={`relative`}>
											<sup className={`absolute text-xs top-0`}>
												({match?.scores.set_1.tiebreak_team_2})
											</sup>
										</p>
									)}
								</div>
							</div>
							<div>
								<div
									className={
										`text-${theme}-primaryColor text-base max-lg:text-base flex flex-row ` +
										(match?.scores.set_2.games_team_2 >
										match?.scores.set_2.games_team_1
											? "font-bold"
											: "")
									}
								>
									{match?.scores.set_2.games_team_2}
									{match?.scores.set_2.tiebreak && (
										<p className={`relative`}>
											<sup className={`absolute text-xs top-0`}>
												({match?.scores.set_2.tiebreak_team_2})
											</sup>
										</p>
									)}
								</div>
							</div>
							<div>
								<div
									className={
										`text-${theme}-primaryColor text-base max-lg:text-base flex flex-row ` +
										(match?.sets === 3 &&
										match?.scores.set_3.games_team_2 === 0 &&
										match?.scores.set_3.games_team_1 === 0
											? ""
											: match?.scores.set_3.games_team_2 >
											  match?.scores.set_3.games_team_1
											? "font-bold"
											: "")
									}
								>
									{match?.sets === 3 &&
									match?.scores.set_3.games_team_2 === 0 &&
									match?.scores.set_3.games_team_1 === 0
										? "-"
										: match?.scores.set_3.games_team_2}
									{match?.scores.set_3.tiebreak && (
										<p className={`relative`}>
											<sup className={`absolute text-xs top-0`}>
												({match?.scores.set_3.tiebreak_team_2})
											</sup>
										</p>
									)}
								</div>
							</div>
							{match?.sets === 5 && (
								<>
									<div>
										<div
											className={
												`text-${theme}-primaryColor text-base max-lg:text-base flex flex-row ` +
												(match?.scores.set_4.games_team_2 >
												match?.scores.set_4.games_team_1
													? "font-bold"
													: "")
											}
										>
											{match?.scores.set_4.games_team_2 === 0 &&
											match?.scores.set_4.games_team_1 === 0
												? "-"
												: match?.scores.set_4.games_team_2}
											{match?.scores.set_4.tiebreak && (
												<p className={`relative`}>
													<sup className={`absolute text-xs top-0`}>
														({match?.scores.set_4.tiebreak_team_2})
													</sup>
												</p>
											)}
										</div>
									</div>
									<div>
										<div
											className={
												`text-${theme}-primaryColor text-base max-lg:text-base flex flex-row ` +
												(match?.scores.set_5.games_team_2 >
												match?.scores.set_5.games_team_1
													? "font-bold"
													: "")
											}
										>
											{match?.scores.set_5.games_team_2 === 0 &&
											match?.scores.set_5.games_team_1 === 0
												? "-"
												: match?.scores.set_5.games_team_2}
											{match?.scores.set_5.tiebreak && (
												<p className={`relative`}>
													<sup className={`absolute text-xs top-0`}>
														({match?.scores.set_5.tiebreak_team_2})
													</sup>
												</p>
											)}
										</div>
									</div>
								</>
							)}
						</div>
					</div>
					<div
						className={`flex flex-row justify-between items-center px-2 mb-1`}
					>
						<p
							className={`text-gray-400 text-xs font-gothamBold max-lg:text-[10px] uppercase`}
						>
							{/*  time is in 1.3 for 1 hour and 30 minutes */}
							Duración:{" "}
							{match?.duration
								? `${Math.trunc(match?.duration)}h ${
										(match?.duration - Math.trunc(match?.duration)).toFixed(2) *
										100
								  }m`
								: "No disponible"}
						</p>
						<p
							className={`text-gray-400 text-xs font-gothamBold max-lg:text-[10px] uppercase`}
						>
							{match?.category === "singles" ? "Singles" : "Dobles"}
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
