import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function CourtCard({ court }) {
	const theme = useSelector((state) => state.theme);
	return (
		<a
			className={`flex flex-col items-center justify-center border-2 border-${theme}-secondaryColor overflow-hidden pb-2 rounded-md cursor-pointer`}
			href={court.maps_link}
			target="_blank"
		>
			<img
				src={court?.image}
				alt={court?.name}
				className={`w-full border-t-inherit h-full min-h-[439px]
				max-lg:min-h-[300px] max-md:min-h-[210px] object-cover`}
			/>
			<h1
				className={`text-primaryColor font-bold text-base mt-2 uppercase max-lg:text-sm text-center`}
			>
				{court?.name}
			</h1>
			<p className={`text-primaryColor text-sm max-lg:text-xs text-center`}>
				Superficie:{" "}
				{court?.surface === "hard"
					? "Dura"
					: court?.surface === "clay"
					? "Polvo de ladrillo"
					: "Pasto"}
			</p>
			<p className={`text-primaryColor text-sm max-lg:text-xs text-center`}>
				{court?.address}, {court?.city}, {court?.province}, {court?.country}
			</p>
		</a>
	);
}
