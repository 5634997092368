export const GET_PLAYERS = "GET_PLAYERS";
export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_DOUBLES = "GET_TEAMS_DOUBLES";
export const GET_TEAMS_SINGLES = "GET_TEAMS_SINGLES";
export const GET_COURTS = "GET_COURTS";
export const GET_MATCHES = "GET_MATCHES";
export const CREATE_MATCH = "CREATE_MATCH";
export const CREATE_PLAYER = "CREATE_PLAYER";
export const CREATE_TEAM = "CREATE_TEAM";
export const END_MATCH = "END_MATCH";
export const CLEAN_STATE = "CLEAN_STATE";
export const SET_THEME = "SET_THEME";
