import { useEffect, useState } from "react";
import { createPlayer, uploadImage } from "../firebase/client";
import { useSelector } from "react-redux";

export default function CreatePlayer() {
	const [submitting, setSubmitting] = useState(false);
	const theme = useSelector((state) => state.theme);
	const [player, setPlayer] = useState({
		name: "",
		birth_date: "",
		height: 0,
		preferred_hand: "",
		avatar: "",
		gender: "",
	});
	const [task, setTask] = useState(null);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (submitting) return;
		setSubmitting(true);
		if (
			!player.name ||
			!player.birth_date ||
			!player.height ||
			!player.preferred_hand ||
			!player.avatar
		) {
			setSubmitting(false);
			alert("Completa todos los campos");
			return;
		}
		await createPlayer({
			name: player.name,
			birth_date: player.birth_date,
			height: Number(player.height),
			preferred_hand: player.preferred_hand,
			avatar: player.avatar,
			gender: player.gender,
			ranking_score: 0,
		})
			.then(() => {
				setPlayer({
					name: "",
					birth_date: "",
					height: 0,
					preferred_hand: "",
					gender: "",
					avatar: "",
				});
				setSubmitting(false);
				alert("Jugador creado correctamente");
				window.location.reload();
			})
			.catch((error) => {
				console.error(error);
				setSubmitting(false);
				alert("Hubo un error al crear el jugador");
			});
	};

	const handleUpload = async (e) => {
		const file = e.target.files[0];
		if (!file) return;
		if (
			!file.type.includes("image/png") &&
			!file.type.includes("image/jpeg") &&
			!file.type.includes("image/jpg") &&
			!file.type.includes("image/webp")
		) {
			alert("El archivo debe ser una imagen en formato PNG, JPEG, JPG o WEBP");
			return;
		}
		const task = uploadImage(file);
		setTask(task);
	};

	useEffect(() => {
		if (task) {
			const onProgress = () => {};
			const onError = () => {
				setTask(null);
				alert("Hubo un error al subir la imagen");
			};
			const onComplete = () => {
				task.snapshot.ref.getDownloadURL().then((url) => {
					setPlayer({
						...player,
						avatar: url,
					});
				});
			};
			task.on("state_changed", onProgress, onError, onComplete);
		}
	}, [task]);

	return (
		<form className={`w-full flex flex-col justify-center gap-2`}>
			<label htmlFor="name" className={`text-${theme}-primaryColor text-sm`}>
				Nombre:
			</label>
			<input
				id="name"
				type="text"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
				onChange={(e) =>
					setPlayer({
						...player,
						name: e.target.value,
					})
				}
				value={player.name}
			/>
			<label
				htmlFor="birth_date"
				className={`text-${theme}-primaryColor text-sm`}
			>
				Fecha de nacimiento:
			</label>
			<input
				id="birth_date"
				type="date"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
				onChange={(e) =>
					setPlayer({
						...player,
						birth_date: e.target.value,
					})
				}
				value={player.birth_date}
			/>
			<label htmlFor="gender" className={`text-${theme}-primaryColor text-sm`}>
				Género:
			</label>
			<select
				id="gender"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
				onChange={(e) =>
					setPlayer({
						...player,
						gender: e.target.value,
					})
				}
				value={player.gender}
			>
				<option value="" selected disabled>
					Selecciona un género
				</option>
				<option value="male">Masculino</option>
				<option value="female">Femenino</option>
			</select>
			<label htmlFor="height" className={`text-${theme}-primaryColor text-sm`}>
				Altura (cm):
			</label>
			<input
				id="height"
				type="number"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
				onChange={(e) =>
					setPlayer({
						...player,
						height: e.target.value,
					})
				}
				value={player.height}
			/>
			<label
				htmlFor="preferred_hand"
				className={`text-${theme}-primaryColor text-sm`}
			>
				Mano preferida:
			</label>
			<select
				id="preferred_hand"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
				onChange={(e) =>
					setPlayer({
						...player,
						preferred_hand: e.target.value,
					})
				}
				value={player.preferred_hand}
			>
				<option value="" selected disabled>
					Selecciona una mano preferida
				</option>
				<option value="right">Derecha</option>
				<option value="left">Izquierda</option>
			</select>
			<label htmlFor="avatar" className={`text-${theme}-primaryColor text-sm`}>
				Avatar:
			</label>
			<div className={`flex flex-col w-full items-start gap-1`}>
				<img
					src={player.avatar || "/placeholder.jpg"}
					alt={player.name}
					className={`w-24 h-24 rounded-full object-cover border-${theme}-primaryColor border-2`}
				/>
				<input
					id="avatar"
					type="file"
					className={`hidden`}
					onChange={handleUpload}
				/>
				<label
					htmlFor="avatar"
					className={`text-center bg-${theme}-primaryColor text-white text-xs font-gothamBold px-2 py-1 rounded-md cursor-pointer`}
				>
					Subir imagen
				</label>
			</div>

			<button
				className={`w-full bg-${theme}-primaryColor text-white text-sm font-gothamBold px-4 py-2 rounded-md mt-2`}
				onClick={handleSubmit}
				disabled={submitting}
			>
				{submitting ? "Creando jugador..." : "Crear jugador"}
			</button>
		</form>
	);
}
