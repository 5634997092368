import { useSelector } from "react-redux";

export default function Loader() {
	const theme = useSelector((state) => state.theme);
	return (
		<div
			className={`spinner border-solid border-[6.4px] border-${theme}-secondaryColor`}
		></div>
	);
}
