import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setTheme } from "../redux/actions/actions";

export default function Footer() {
	const dispatch = useDispatch();
	const theme = useSelector((state) => state.theme);

	return (
		// stick to the bottom of the page
		<footer
			className={`max-lg:text-xs text-xs text-center my-4 text-${theme}-primaryColor`}
		>
			<p>&copy; Copyright TenniStats 2024. Todos los derechos reservados.</p>
			<div className={`flex justify-center gap-2 my-1`}>
				<p
					className={
						`cursor-pointer` + (theme === "usopen" ? " font-bold" : "")
					}
					onClick={() => dispatch(setTheme("usopen"))}
				>
					USO theme
				</p>
				<p
					className={
						`cursor-pointer` + (theme === "rolandgarros" ? " font-bold" : "")
					}
					onClick={() => dispatch(setTheme("rolandgarros"))}
				>
					RG theme
				</p>
				<p
					className={
						`cursor-pointer` + (theme === "wimbledon" ? " font-bold" : "")
					}
					onClick={() => dispatch(setTheme("wimbledon"))}
				>
					WBD theme
				</p>
				<p
					className={
						`cursor-pointer` + (theme === "australianopen" ? " font-bold" : "")
					}
					onClick={() => dispatch(setTheme("australianopen"))}
				>
					AO theme
				</p>
			</div>
			<Link
				to="/admin"
				className={`text-${theme}-primaryColor hover:underline`}
			>
				Ir al panel de administrador
			</Link>
		</footer>
	);
}
