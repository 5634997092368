import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader } from "../components";
import { useDispatch, useSelector } from "react-redux";
import {
	getMatches,
	getTeamsDoubles,
	getCourts,
} from "../redux/actions/actions";

export default function Team() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const teams = useSelector((state) => state.teamsDoubles);
	const [team, setTeam] = useState(
		teams && teams.find((team) => team.id === id)
	);
	const theme = useSelector((state) => state.theme);
	const matches = useSelector((state) => state.endedMatches);
	const courts = useSelector((state) => state.courts);
	const [section, setSection] = useState("stats");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (matches.length === 0) dispatch(getMatches());
		if (teams.length === 0) dispatch(getTeamsDoubles());
		if (courts.length === 0) dispatch(getCourts());
	}, [dispatch, matches, teams, courts]);

	useEffect(() => {
		if (
			matches.length > 0 &&
			teams.length > 0 &&
			courts.length > 0 &&
			team?.id
		) {
			setLoading(false);
		}
	}, [matches, teams, courts]);

	useEffect(() => {
		setTeam(teams.find((team) => team.id === id));
	}, [id, teams]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [section]);

	return (
		<div
			className={`flex flex-col max-lg:gap-3 gap-6 mt-2 items-center justify-center`}
		>
			{loading ? (
				<div className={`flex justify-center items-center h-[60svh]`}>
					<Loader className={`w-10`} />
				</div>
			) : (
				<div className={`w-full max-w-[800px] px-2`}>
					{matches && teams && team && (
						<div className={`flex flex-col items-center justify-center`}>
							<div
								className={`flex flex-row items-end justify-between w-full border-b-2 border-${theme}-secondaryColor pb-2 max-lg:items-start`}
							>
								<div className={`flex flex-row items-center justify-center`}>
									<p
										className={`text-${theme}-primaryColor text-5xl font-gothamBold max-lg:text-3xl`}
									>
										#{team?.ranking_position}
									</p>
									<div className={`flex flex-col items-start`}>
										<p
											className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-xs`}
										>
											RANKING
										</p>
										<p
											className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-xs -mt-3 max-lg:-mt-1`}
										>
											DOBLES
										</p>
									</div>
								</div>
								<div className={`flex flex-col items-center justify-center`}>
									<div
										className={`flex flex-row items-center justify-center gap-2`}
									>
										<img
											src={team.player_1?.avatar}
											alt={team.player_1?.name}
											className={`w-52 h-52 object-cover max-lg:w-20 max-lg:h-20 border-2 border-${theme}-primaryColor rounded-full`}
										/>
										<img
											src={team.player_2?.avatar}
											alt={team.player_2?.name}
											className={`w-52 h-52 object-cover max-lg:w-20 max-lg:h-20 border-2 border-${theme}-primaryColor rounded-full`}
										/>
									</div>
									<div
										className={`flex flex-col items-center justify-center mt-2`}
									>
										<h1
											className={`text-${theme}-primaryColor font-gothamBold text-2xl uppercase max-lg:text-sm`}
										>
											{team.player_1?.name}
										</h1>
										<p
											className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-xs -my-2 max-lg:-my-1`}
										>
											&
										</p>
										<h1
											className={`text-${theme}-primaryColor font-gothamBold text-2xl uppercase max-lg:text-sm`}
										>
											{team.player_2?.name}
										</h1>
									</div>
								</div>

								<div
									className={`flex flex-row items-center justify-center invisible`}
								>
									<p
										className={`text-${theme}-primaryColor text-5xl font-gothamBold max-lg:text-3xl `}
									>
										#{team?.ranking_position}
									</p>
									<div className={`flex flex-col items-start`}>
										<p
											className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-xs`}
										>
											RANKING
										</p>
										<p
											className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-xs -mt-3 max-lg:-mt-1`}
										>
											DOBLES
										</p>
									</div>
								</div>
							</div>
							<div
								className={`flex flex-row justify-center items-center w-full pt-2 gap-4 border-b-2 border-gray-200`}
							>
								<p
									className={
										"text-${theme}-primaryColor cursor-pointer text-base font-semibold max-lg:text-[14px] " +
										(section === "stats"
											? `border-b-2 border-${theme}-secondaryColor`
											: "border-b-2 border-transparent")
									}
									onClick={() => setSection("stats")}
								>
									ESTADÍSTICAS
								</p>
								<div
									className={`border-l-2 border-${theme}-primaryColor h-4 max-lg:h-3`}
								></div>
								<p
									className={
										`
										"text-${theme}-primaryColor cursor-pointer text-base font-semibold max-lg:text-[14px] ` +
										(section === "history"
											? `border-b-2 border-${theme}-secondaryColor`
											: "border-b-2 border-transparent")
									}
									onClick={() => setSection("history")}
								>
									HISTORIALES
								</p>
							</div>
							<div
								className={`flex flex-col items-center justify-center w-full`}
							>
								{section === "stats" && (
									<div
										className={`flex flex-col items-center justify-center w-full`}
									>
										<p
											className={`self-start text-${theme}-primaryColor text-lg max-lg:text-sm mt-2 font-gothamBold uppercase`}
										>
											Partidos totales
										</p>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm`}
											>
												Partidos jugados
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm`}
											>
												{
													matches.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													).length
												}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Partidos ganados
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{
													matches
														.filter(
															(match) =>
																match.team_1_id === team?.id ||
																match.team_2_id === team?.id
														)
														.filter(
															(match) => match.winner_team_id === team?.id
														).length
												}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Partidos perdidos
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{
													matches
														.filter(
															(match) =>
																match.team_1_id === team?.id ||
																match.team_2_id === team?.id
														)
														.filter(
															(match) => match.winner_team_id !== team?.id
														).length
												}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Efectividad de partidos
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{isNaN(
													(
														(
															matches
																.filter(
																	(match) =>
																		match.team_1_id === team?.id ||
																		match.team_2_id === team?.id
																)
																.filter(
																	(match) => match.winner_team_id === team?.id
																).length /
															matches.filter(
																(match) =>
																	match.team_1_id === team?.id ||
																	match.team_2_id === team?.id
															).length
														).toFixed(2) * 100
													).toFixed(0)
												)
													? 0
													: (
															(
																matches
																	.filter(
																		(match) =>
																			match.team_1_id === team?.id ||
																			match.team_2_id === team?.id
																	)
																	.filter(
																		(match) => match.winner_team_id === team?.id
																	).length /
																matches.filter(
																	(match) =>
																		match.team_1_id === team?.id ||
																		match.team_2_id === team?.id
																).length
															).toFixed(2) * 100
													  ).toFixed(0)}
												%
											</p>
										</div>

										<hr className={`w-full border-gray-200 mt-2`} />
										<p
											className={`self-start text-${theme}-primaryColor text-lg max-lg:text-sm mt-2 font-gothamBold uppercase`}
										>
											SETS
										</p>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm`}
											>
												Sets jugados
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm`}
											>
												{matches
													.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													)
													.map((match) => {
														return Object.values(match.scores).map((score) => {
															return score.games_team_1 !== 0 ||
																score.games_team_2 !== 0
																? 1
																: 0;
														});
													})
													.flat()
													.reduce((acc, curr) => acc + curr, 0)}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Sets ganados
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{matches
													.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													)
													.map((match) => {
														return Object.values(match.scores).map((score) => {
															return match.team_1_id === team?.id
																? score.games_team_1 > score.games_team_2
																	? 1
																	: 0
																: score.games_team_2 > score.games_team_1
																? 1
																: 0;
														});
													})
													.flat()
													.reduce((acc, curr) => acc + curr, 0)}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Sets perdidos
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{matches
													.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													)
													.map((match) => {
														return Object.values(match.scores).map((score) => {
															return match.team_1_id === team?.id
																? score.games_team_1 < score.games_team_2
																	? 1
																	: 0
																: score.games_team_2 < score.games_team_1
																? 1
																: 0;
														});
													})
													.flat()
													.reduce((acc, curr) => acc + curr, 0)}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Efectividad de sets
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{isNaN(
													(
														(
															matches
																.filter(
																	(match) =>
																		match.team_1_id === team?.id ||
																		match.team_2_id === team?.id
																)
																.map((match) => {
																	return Object.values(match.scores).map(
																		(score) => {
																			return match.team_1_id === team?.id
																				? score.games_team_1 >
																				  score.games_team_2
																					? 1
																					: 0
																				: score.games_team_2 >
																				  score.games_team_1
																				? 1
																				: 0;
																		}
																	);
																})
																.flat()
																.reduce((acc, curr) => acc + curr, 0) /
															matches
																.filter(
																	(match) =>
																		match.team_1_id === team?.id ||
																		match.team_2_id === team?.id
																)
																.map((match) => {
																	return Object.values(match.scores).map(
																		(score) => {
																			return score.games_team_1 !== 0 ||
																				score.games_team_2 !== 0
																				? 1
																				: 0;
																		}
																	);
																})
																.flat()
																.reduce((acc, curr) => acc + curr, 0)
														).toFixed(2) * 100
													).toFixed(0)
												)
													? 0
													: (
															(
																matches
																	.filter(
																		(match) =>
																			match.team_1_id === team?.id ||
																			match.team_2_id === team?.id
																	)
																	.map((match) => {
																		return Object.values(match.scores).map(
																			(score) => {
																				return match.team_1_id === team?.id
																					? score.games_team_1 >
																					  score.games_team_2
																						? 1
																						: 0
																					: score.games_team_2 >
																					  score.games_team_1
																					? 1
																					: 0;
																			}
																		);
																	})
																	.flat()
																	.reduce((acc, curr) => acc + curr, 0) /
																matches
																	.filter(
																		(match) =>
																			match.team_1_id === team?.id ||
																			match.team_2_id === team?.id
																	)
																	.map((match) => {
																		return Object.values(match.scores).map(
																			(score) => {
																				return score.games_team_1 !== 0 ||
																					score.games_team_2 !== 0
																					? 1
																					: 0;
																			}
																		);
																	})
																	.flat()
																	.reduce((acc, curr) => acc + curr, 0)
															).toFixed(2) * 100
													  ).toFixed(0)}
												%
											</p>
										</div>
										<hr className={`w-full border-gray-200 mt-2`} />
										<p
											className={`self-start text-${theme}-primaryColor text-lg max-lg:text-sm mt-2 font-gothamBold uppercase`}
										>
											GAMES
										</p>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm`}
											>
												Games jugados
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm`}
											>
												{matches
													.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													)
													.map((match) => {
														return Object.values(match.scores).map((score) => {
															return score.games_team_1 + score.games_team_2;
														});
													})
													.flat()
													.reduce((acc, curr) => acc + curr, 0)}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Games ganados
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{matches
													.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													)
													.map((match) => {
														return Object.values(match.scores).map((score) => {
															return match.team_1_id === team?.id
																? score.games_team_1
																: score.games_team_2;
														});
													})
													.flat()
													.reduce((acc, curr) => acc + curr, 0)}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Games perdidos
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{matches
													.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													)
													.map((match) => {
														return Object.values(match.scores).map((score) => {
															return match.team_1_id === team?.id
																? score.games_team_2
																: score.games_team_1;
														});
													})
													.flat()
													.reduce((acc, curr) => acc + curr, 0)}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Efectividad de games
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{isNaN(
													(
														(
															matches
																.filter(
																	(match) =>
																		match.team_1_id === team?.id ||
																		match.team_2_id === team?.id
																)
																.map((match) => {
																	return Object.values(match.scores).map(
																		(score) => {
																			return match.team_1_id === team?.id
																				? score.games_team_1
																				: score.games_team_2;
																		}
																	);
																})
																.flat()
																.reduce((acc, curr) => acc + curr, 0) /
															matches
																.filter(
																	(match) =>
																		match.team_1_id === team?.id ||
																		match.team_2_id === team?.id
																)
																.map((match) => {
																	return Object.values(match.scores).map(
																		(score) => {
																			return (
																				score.games_team_1 + score.games_team_2
																			);
																		}
																	);
																})
																.flat()
																.reduce((acc, curr) => acc + curr, 0)
														).toFixed(2) * 100
													).toFixed(0)
												)
													? 0
													: (
															(
																matches
																	.filter(
																		(match) =>
																			match.team_1_id === team?.id ||
																			match.team_2_id === team?.id
																	)
																	.map((match) => {
																		return Object.values(match.scores).map(
																			(score) => {
																				return match.team_1_id === team?.id
																					? score.games_team_1
																					: score.games_team_2;
																			}
																		);
																	})
																	.flat()
																	.reduce((acc, curr) => acc + curr, 0) /
																matches
																	.filter(
																		(match) =>
																			match.team_1_id === team?.id ||
																			match.team_2_id === team?.id
																	)
																	.map((match) => {
																		return Object.values(match.scores).map(
																			(score) => {
																				return (
																					score.games_team_1 +
																					score.games_team_2
																				);
																			}
																		);
																	})
																	.flat()
																	.reduce((acc, curr) => acc + curr, 0)
															).toFixed(2) * 100
													  ).toFixed(0)}
												%
											</p>
										</div>

										<hr className={`w-full border-gray-200 mt-2`} />
										<p
											className={`self-start text-${theme}-primaryColor text-lg max-lg:text-sm mt-2 font-gothamBold uppercase`}
										>
											TIEBREAKS
										</p>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm`}
											>
												Tiebreaks jugados
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm`}
											>
												{matches
													.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													)
													.map((match) => {
														return Object.values(match.scores).map((score) => {
															return score.tiebreak ? 1 : 0;
														});
													})
													.flat()
													.reduce((acc, curr) => acc + curr, 0)}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Tiebreaks ganados
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{matches
													.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													)
													.map((match) => {
														return Object.values(match.scores).map((score) => {
															return match.team_1_id === team?.id
																? score.tiebreak_team_1 > score.tiebreak_team_2
																	? 1
																	: 0
																: score.tiebreak_team_2 > score.tiebreak_team_1
																? 1
																: 0;
														});
													})
													.flat()
													.reduce((acc, curr) => acc + curr, 0)}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Tiebreaks perdidos
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{matches
													.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													)
													.map((match) => {
														return Object.values(match.scores).map((score) => {
															return match.team_1_id === team?.id
																? score.tiebreak_team_1 < score.tiebreak_team_2
																	? 1
																	: 0
																: score.tiebreak_team_2 < score.tiebreak_team_1
																? 1
																: 0;
														});
													})
													.flat()
													.reduce((acc, curr) => acc + curr, 0)}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Efectividad de tiebreaks
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{isNaN(
													(
														(
															matches
																.filter(
																	(match) =>
																		match.team_1_id === team?.id ||
																		match.team_2_id === team?.id
																)
																.map((match) => {
																	return Object.values(match.scores).map(
																		(score) => {
																			return match.team_1_id === team?.id
																				? score.tiebreak_team_1 >
																				  score.tiebreak_team_2
																					? 1
																					: 0
																				: score.tiebreak_team_2 >
																				  score.tiebreak_team_1
																				? 1
																				: 0;
																		}
																	);
																})
																.flat()
																.reduce((acc, curr) => acc + curr, 0) /
															matches
																.filter(
																	(match) =>
																		match.team_1_id === team?.id ||
																		match.team_2_id === team?.id
																)
																.map((match) => {
																	return Object.values(match.scores).map(
																		(score) => {
																			return score.tiebreak ? 1 : 0;
																		}
																	);
																})
																.flat()
																.reduce((acc, curr) => acc + curr, 0)
														).toFixed(2) * 100
													).toFixed(0)
												)
													? 0
													: (
															(
																matches
																	.filter(
																		(match) =>
																			match.team_1_id === team?.id ||
																			match.team_2_id === team?.id
																	)
																	.map((match) => {
																		return Object.values(match.scores).map(
																			(score) => {
																				return match.team_1_id === team?.id
																					? score.tiebreak_team_1 >
																					  score.tiebreak_team_2
																						? 1
																						: 0
																					: score.tiebreak_team_2 >
																					  score.tiebreak_team_1
																					? 1
																					: 0;
																			}
																		);
																	})
																	.flat()
																	.reduce((acc, curr) => acc + curr, 0) /
																matches
																	.filter(
																		(match) =>
																			match.team_1_id === team?.id ||
																			match.team_2_id === team?.id
																	)
																	.map((match) => {
																		return Object.values(match.scores).map(
																			(score) => {
																				return score.tiebreak ? 1 : 0;
																			}
																		);
																	})
																	.flat()
																	.reduce((acc, curr) => acc + curr, 0)
															).toFixed(2) * 100
													  ).toFixed(0)}
												%
											</p>
										</div>
										<hr className={`w-full border-gray-200 mt-2`} />
										<p
											className={`self-start text-${theme}-primaryColor text-lg max-lg:text-sm mt-2 font-gothamBold uppercase`}
										>
											PUNTOS DE TIEBREAKS
										</p>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm `}
											>
												Puntos de tiebreaks jugados
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm `}
											>
												{matches
													.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													)
													.map((match) => {
														return Object.values(match.scores).map((score) => {
															return (
																score.tiebreak_team_1 + score.tiebreak_team_2
															);
														});
													})
													.flat()
													.reduce((acc, curr) => acc + curr, 0)}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Puntos de tiebreaks ganados
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{matches
													.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													)
													.map((match) => {
														return Object.values(match.scores).map((score) => {
															return match.team_1_id === team?.id
																? score.tiebreak_team_1
																: score.tiebreak_team_2;
														});
													})
													.flat()
													.reduce((acc, curr) => acc + curr, 0)}
											</p>
										</div>
										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Puntos de tiebreaks perdidos
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{matches
													.filter(
														(match) =>
															match.team_1_id === team?.id ||
															match.team_2_id === team?.id
													)
													.map((match) => {
														return Object.values(match.scores).map((score) => {
															return match.team_1_id === team?.id
																? score.tiebreak_team_2
																: score.tiebreak_team_1;
														});
													})
													.flat()
													.reduce((acc, curr) => acc + curr, 0)}
											</p>
										</div>

										<div
											className={`flex flex-row items-center justify-between w-full`}
										>
											<p
												className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
											>
												Efectividad de puntos de tiebreaks
											</p>
											<p
												className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
											>
												{isNaN(
													(
														(
															matches
																.filter(
																	(match) =>
																		match.team_1_id === team?.id ||
																		match.team_2_id === team?.id
																)
																.map((match) => {
																	return Object.values(match.scores).map(
																		(score) => {
																			return match.team_1_id === team?.id
																				? score.tiebreak_team_1
																				: score.tiebreak_team_2;
																		}
																	);
																})
																.flat()
																.reduce((acc, curr) => acc + curr, 0) /
															matches
																.filter(
																	(match) =>
																		match.team_1_id === team?.id ||
																		match.team_2_id === team?.id
																)
																.map((match) => {
																	return Object.values(match.scores).map(
																		(score) => {
																			return (
																				score.tiebreak_team_1 +
																				score.tiebreak_team_2
																			);
																		}
																	);
																})
																.flat()
																.reduce((acc, curr) => acc + curr, 0)
														).toFixed(2) * 100
													).toFixed(0)
												)
													? 0
													: (
															(
																matches
																	.filter(
																		(match) =>
																			match.team_1_id === team?.id ||
																			match.team_2_id === team?.id
																	)
																	.map((match) => {
																		return Object.values(match.scores).map(
																			(score) => {
																				return match.team_1_id === team?.id
																					? score.tiebreak_team_1
																					: score.tiebreak_team_2;
																			}
																		);
																	})
																	.flat()
																	.reduce((acc, curr) => acc + curr, 0) /
																matches
																	.filter(
																		(match) =>
																			match.team_1_id === team?.id ||
																			match.team_2_id === team?.id
																	)
																	.map((match) => {
																		return Object.values(match.scores).map(
																			(score) => {
																				return (
																					score.tiebreak_team_1 +
																					score.tiebreak_team_2
																				);
																			}
																		);
																	})
																	.flat()
																	.reduce((acc, curr) => acc + curr, 0)
															).toFixed(2) * 100
													  ).toFixed(0)}
												%
											</p>
										</div>
										{courts.map((court) => {
											return (
												<div key={court.id} className={`w-full`}>
													<hr className={`w-full border-gray-200 mt-2`} />
													<p
														className={`self-start text-${theme}-primaryColor text-lg max-lg:text-sm mt-2 font-gothamBold uppercase`}
													>
														Partidos en {court.name}
													</p>
													<div
														className={`flex flex-row items-center justify-between w-full`}
													>
														<p
															className={`text-${theme}-primaryColor text-lg max-lg:text-sm`}
														>
															Partidos jugados
														</p>
														<p
															className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm`}
														>
															{
																matches
																	.filter(
																		(match) => match.court_id === court.id
																	)
																	.filter(
																		(match) =>
																			match.team_1_id === team?.id ||
																			match.team_2_id === team?.id
																	).length
															}
														</p>
													</div>
													<div
														className={`flex flex-row items-center justify-between w-full`}
													>
														<p
															className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
														>
															Partidos ganados
														</p>
														<p
															className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
														>
															{
																matches
																	.filter(
																		(match) => match.court_id === court.id
																	)
																	.filter(
																		(match) =>
																			match.team_1_id === team?.id ||
																			match.team_2_id === team?.id
																	)
																	.filter(
																		(match) =>
																			match.winner_team?.id === team?.id
																	).length
															}
														</p>
													</div>
													<div
														className={`flex flex-row items-center justify-between w-full`}
													>
														<p
															className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
														>
															Partidos perdidos
														</p>
														<p
															className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
														>
															{
																matches
																	.filter(
																		(match) => match.court_id === court.id
																	)
																	.filter(
																		(match) =>
																			match.team_1_id === team?.id ||
																			match.team_2_id === team?.id
																	)
																	.filter(
																		(match) =>
																			match.winner_team?.id !== team?.id
																	).length
															}
														</p>
													</div>
													<div
														className={`flex flex-row items-center justify-between w-full`}
													>
														<p
															className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
														>
															Efectividad de partidos
														</p>
														<p
															className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
														>
															{isNaN(
																(
																	(
																		(matches
																			.filter(
																				(match) => match.court_id === court.id
																			)
																			.filter(
																				(match) =>
																					match.team_1_id === team?.id ||
																					match.team_2_id === team?.id
																			)
																			.filter(
																				(match) =>
																					match.winner_team?.id === team?.id
																			).length || 0) /
																		(matches
																			.filter(
																				(match) => match.court_id === court.id
																			)
																			.filter(
																				(match) =>
																					match.team_1_id === team?.id ||
																					match.team_2_id === team?.id
																			).length || 0)
																	).toFixed(2) * 100
																).toFixed(0)
															)
																? 0
																: (
																		(
																			(matches
																				.filter(
																					(match) => match.court_id === court.id
																				)
																				.filter(
																					(match) =>
																						match.team_1_id === team?.id ||
																						match.team_2_id === team?.id
																				)
																				.filter(
																					(match) =>
																						match.winner_team?.id === team?.id
																				).length || 0) /
																			(matches
																				.filter(
																					(match) => match.court_id === court.id
																				)
																				.filter(
																					(match) =>
																						match.team_1_id === team?.id ||
																						match.team_2_id === team?.id
																				).length || 0)
																		).toFixed(2) * 100
																  ).toFixed(0)}
															%
														</p>
													</div>
												</div>
											);
										})}
									</div>
								)}
								{section === "history" && (
									<div
										className={`flex flex-col items-center justify-center w-full`}
									>
										{teams
											.filter(
												(t) =>
													t.id !== team?.id &&
													t.player_1_id !== team.player_1_id &&
													t.player_2_id !== team.player_2_id &&
													t.player_1_id !== team.player_2_id &&
													t.player_2_id !== team.player_1_id
											)
											.map((t, index) => {
												return (
													<div key={t.id} className={`w-full`}>
														{index !== 0 && (
															<hr className={`w-full border-gray-200 mt-2`} />
														)}
														<p
															className={`self-start text-${theme}-primaryColor text-lg max-lg:text-sm mt-2 font-gothamBold uppercase`}
														>
															HISTORIAL CONTRA {t.player_1?.name} &{" "}
															{t.player_2?.name}
														</p>
														<div
															className={`flex flex-row items-center justify-between w-full`}
														>
															<p
																className={`text-${theme}-primaryColor text-lg max-lg:text-sm`}
															>
																Partidos jugados totales
															</p>
															<p
																className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm`}
															>
																{
																	matches
																		.filter(
																			(match) =>
																				match.team_1_id === team?.id ||
																				match.team_2_id === team?.id
																		)
																		.filter((match) =>
																			match.team_1_id === team?.id
																				? match.team_2_id === t.id
																				: match.team_1_id === t.id
																		).length
																}
															</p>
														</div>
														<div
															className={`flex flex-row items-center justify-between w-full`}
														>
															<p
																className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
															>
																Partidos ganados totales
															</p>
															<p
																className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
															>
																{
																	matches
																		.filter(
																			(match) =>
																				match.team_1_id === team?.id ||
																				match.team_2_id === team?.id
																		)
																		.filter((match) =>
																			match.team_1_id === team?.id
																				? match.team_2_id === t.id
																				: match.team_1_id === t.id
																		)
																		.filter(
																			(match) =>
																				match.winner_team_id === team?.id
																		).length
																}
															</p>
														</div>
														<div
															className={`flex flex-row items-center justify-between w-full`}
														>
															<p
																className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
															>
																Partidos perdidos totales
															</p>
															<p
																className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
															>
																{
																	matches
																		.filter(
																			(match) =>
																				match.team_1_id === team?.id ||
																				match.team_2_id === team?.id
																		)
																		.filter((match) =>
																			match.team_1_id === team?.id
																				? match.team_2_id === t.id
																				: match.team_1_id === t.id
																		)
																		.filter(
																			(match) =>
																				match.winner_team_id !== team?.id
																		).length
																}
															</p>
														</div>
														<div
															className={`flex flex-row items-center justify-between w-full`}
														>
															<p
																className={`text-${theme}-primaryColor text-lg max-lg:text-sm mt-2`}
															>
																Efectividad de partidos totales
															</p>
															<p
																className={`text-${theme}-primaryColor text-lg font-gothamBold max-lg:text-sm mt-2`}
															>
																{isNaN(
																	(
																		(
																			(matches
																				.filter(
																					(match) =>
																						match.team_1_id === team?.id ||
																						match.team_2_id === team?.id
																				)
																				.filter((match) =>
																					match.team_1_id === team?.id
																						? match.team_2_id === t.id
																						: match.team_1_id === t.id
																				)
																				.filter(
																					(match) =>
																						match.winner_team_id === team?.id
																				).length || 0) /
																			(matches
																				.filter(
																					(match) =>
																						match.team_1_id === team?.id ||
																						match.team_2_id === team?.id
																				)
																				.filter((match) =>
																					match.team_1_id === team?.id
																						? match.team_2_id === t.id
																						: match.team_1_id === t.id
																				).length || 0)
																		).toFixed(2) * 100
																	).toFixed(0)
																)
																	? 0
																	: (
																			(
																				(matches
																					.filter(
																						(match) =>
																							match.team_1_id === team?.id ||
																							match.team_2_id === team?.id
																					)
																					.filter((match) =>
																						match.team_1_id === team?.id
																							? match.team_2_id === t.id
																							: match.team_1_id === t.id
																					)
																					.filter(
																						(match) =>
																							match.winner_team_id === team?.id
																					).length || 0) /
																				(matches
																					.filter(
																						(match) =>
																							match.team_1_id === team?.id ||
																							match.team_2_id === team?.id
																					)
																					.filter((match) =>
																						match.team_1_id === team?.id
																							? match.team_2_id === t.id
																							: match.team_1_id === t.id
																					).length || 0)
																			).toFixed(2) * 100
																	  ).toFixed(0)}
																%
															</p>
														</div>
													</div>
												);
											})}
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
