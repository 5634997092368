import { useEffect, useState } from "react";
import { createCourt, uploadImage } from "../firebase/client";
import { useSelector } from "react-redux";

export default function CreateCourt() {
	const [submitting, setSubmitting] = useState(false);
	const theme = useSelector((state) => state.theme);
	const [court, setCourt] = useState({
		name: "",
		address: "",
		city: "",
		province: "",
		country: "",
		maps_link: "",
		surface: "",
		image: "",
	});
	const [task, setTask] = useState(null);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (submitting) return;
		setSubmitting(true);
		if (
			!court.name ||
			!court.address ||
			!court.city ||
			!court.province ||
			!court.country ||
			!court.maps_link ||
			!court.surface ||
			!court.image
		) {
			setSubmitting(false);
			alert("Completa todos los campos");
			return;
		}
		await createCourt({
			name: court.name,
			address: court.address,
			city: court.city,
			province: court.province,
			country: court.country,
			maps_link: court.maps_link,
			surface: court.surface,
			image: court.image,
		})
			.then(() => {
				setCourt({
					name: "",
					address: "",
					city: "",
					province: "",
					country: "",
					maps_link: "",
					surface: "",
					image: "",
				});
				setSubmitting(false);
				alert("Cancha creada correctamente");
				window.location.reload();
			})
			.catch((error) => {
				console.error(error);
				setSubmitting(false);
				alert("Hubo un error al crear la cancha");
			});
	};

	const handleUpload = async (e) => {
		const file = e.target.files[0];
		if (!file) return;
		if (
			!file.type.includes("image/png") &&
			!file.type.includes("image/jpeg") &&
			!file.type.includes("image/jpg") &&
			!file.type.includes("image/webp")
		) {
			alert("El archivo debe ser una imagen en formato PNG, JPEG, JPG o WEBP");
			return;
		}
		const task = uploadImage(file);
		setTask(task);
	};

	useEffect(() => {
		if (task) {
			const onProgress = () => {};
			const onError = () => {
				setTask(null);
				alert("Hubo un error al subir la imagen");
			};
			const onComplete = () => {
				task.snapshot.ref.getDownloadURL().then((url) => {
					setCourt({
						...court,
						image: url,
					});
				});
			};
			task.on("state_changed", onProgress, onError, onComplete);
		}
	}, [task]);

	return (
		<form className={`w-full flex flex-col justify-center gap-2`}>
			<label htmlFor="name" className={`text-${theme}-primaryColor text-sm`}>
				Nombre:
			</label>
			<input
				id="name"
				type="text"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
				onChange={(e) =>
					setCourt({
						...court,
						name: e.target.value,
					})
				}
				value={court.name}
			/>
			<label htmlFor="address" className={`text-${theme}-primaryColor text-sm`}>
				Dirección:
			</label>
			<input
				id="address"
				type="text"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
				onChange={(e) =>
					setCourt({
						...court,
						address: e.target.value,
					})
				}
				value={court.address}
			/>
			<label htmlFor="city" className={`text-${theme}-primaryColor text-sm`}>
				Ciudad:
			</label>
			<input
				id="city"
				type="text"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
				onChange={(e) =>
					setCourt({
						...court,
						city: e.target.value,
					})
				}
				value={court.city}
			/>
			<label
				htmlFor="province"
				className={`text-${theme}-primaryColor text-sm`}
			>
				Provincia:
			</label>
			<input
				id="province"
				type="text"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
				onChange={(e) =>
					setCourt({
						...court,
						province: e.target.value,
					})
				}
				value={court.province}
			/>
			<label htmlFor="country" className={`text-${theme}-primaryColor text-sm`}>
				País:
			</label>
			<input
				id="country"
				type="text"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
				onChange={(e) =>
					setCourt({
						...court,
						country: e.target.value,
					})
				}
				value={court.country}
			/>
			<label
				htmlFor="maps_link"
				className={`text-${theme}-primaryColor text-sm`}
			>
				Enlace de Google Maps:
			</label>
			<input
				id="maps_link"
				type="text"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
				onChange={(e) =>
					setCourt({
						...court,
						maps_link: e.target.value,
					})
				}
				value={court.maps_link}
			/>
			<label htmlFor="surface" className={`text-${theme}-primaryColor text-sm`}>
				Superficie:
			</label>
			<select
				id="surface"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
				onChange={(e) =>
					setCourt({
						...court,
						surface: e.target.value,
					})
				}
				value={court.surface}
			>
				<option value="" selected disabled>
					Selecciona una superficie
				</option>
				<option value="hard">Dura</option>
				<option value="clay">Polvo de ladrillo</option>
				<option value="grass">Pasto</option>
			</select>
			<label htmlFor="image" className={`text-${theme}-primaryColor text-sm`}>
				Imágen:
			</label>
			<div className={`flex flex-col w-full items-start gap-1`}>
				<img
					src={court.image || "/court-placeholder.jpg"}
					alt={court.name}
					className={`w-72 h-44 object-cover border-${theme}-primaryColor border-2`}
				/>
				<input
					id="image"
					type="file"
					className={`hidden`}
					onChange={handleUpload}
				/>
				<label
					htmlFor="image"
					className={`text-center bg-${theme}-primaryColor text-white text-xs font-gothamBold px-2 py-1 rounded-md cursor-pointer`}
				>
					Subir imagen
				</label>
			</div>

			<button
				className={`w-full bg-${theme}-primaryColor text-white text-sm font-gothamBold px-4 py-2 rounded-md mt-2`}
				onClick={handleSubmit}
				disabled={submitting}
			>
				{submitting ? "Creando cancha..." : "Crear cancha"}
			</button>
		</form>
	);
}
