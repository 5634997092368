import { useState } from "react";
import { createTeam } from "../firebase/client";
import { useSelector } from "react-redux";

export default function CreateTeam({ teams, players }) {
	const [submitting, setSubmitting] = useState(false);
	const [category, setCategory] = useState("doubles");
	const theme = useSelector((state) => state.theme);
	const [team, setTeam] = useState({
		player_1: "",
		player_2: "",
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		if (submitting) return;
		setSubmitting(true);
		if (
			category === "doubles" &&
			(team.player_1 === "" || team.player_2 === "")
		) {
			alert("Debes seleccionar a dos jugadores.");
			setSubmitting(false);
			return;
		}
		if (category === "singles" && team.player_1 === "") {
			alert("Debes seleccionar a un jugador.");
			setSubmitting(false);
			return;
		}
		createTeam({
			category,
			player_1_id: team.player_1,
			player_2_id: team.player_2,
			ranking_score: 0,
		}).then(() => {
			setSubmitting(false);
			setTeam({
				player_1: "",
				player_2: "",
			});
			alert("Equipo creado correctamente.");
			window.location.reload();
		});
	};

	return (
		<form className={`w-full flex flex-col justify-center gap-2`}>
			<label
				htmlFor={`category`}
				className={`text-${theme}-primaryColor text-sm`}
			>
				Categoría:
			</label>
			<select
				id="category"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1`}
				onChange={(e) => setCategory(e.target.value)}
			>
				<option value="singles" selected={category === "singles"}>
					Singles
				</option>
				<option value="doubles" selected={category === "doubles"}>
					Dobles
				</option>
			</select>
			<label
				htmlFor="player_1"
				className={`text-${theme}-primaryColor text-sm`}
			>
				Jugador 1:
			</label>
			<select
				id="player_1"
				className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1`}
				onChange={(e) =>
					setTeam({
						...team,
						player_1: e.target.value,
					})
				}
			>
				<option value="" disabled selected>
					Selecciona un jugador
				</option>
				{players
					.filter(
						(player) => team.player_2 === "" || player.id !== team.player_2
					)
					.filter((player) =>
						category === "doubles"
							? !teams.some(
									(t) =>
										t.category === "doubles" &&
										((t.player_1_id === player.id &&
											t.player_2_id === team.player_2) ||
											(t.player_2_id === player.id &&
												t.player_1_id === team.player_2))
							  )
							: !teams.some(
									(t) =>
										t.category === "singles" &&
										(t.player_1_id === player.id || t.player_2_id === player.id)
							  )
					)
					.map((player) => (
						<option value={player.id} key={player.id}>
							{player.name}
						</option>
					))}
			</select>
			{category === "doubles" && (
				<>
					<label
						htmlFor={`player_2`}
						className={`text-${theme}-primaryColor text-sm`}
					>
						Jugador 2:
					</label>
					<select
						id="player_2"
						className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1`}
						onChange={(e) =>
							setTeam({
								...team,
								player_2: e.target.value,
							})
						}
					>
						<option value="" disabled selected>
							Selecciona un jugador
						</option>
						{players
							.filter(
								(player) => team.player_1 === "" || player.id !== team.player_1
							)
							.filter((player) =>
								category === "doubles"
									? !teams.some(
											(t) =>
												t.category === "doubles" &&
												((t.player_1_id === player.id &&
													t.player_2_id === team.player_1) ||
													(t.player_2_id === player.id &&
														t.player_1_id === team.player_1))
									  )
									: !teams.some(
											(t) =>
												t.category === "singles" &&
												(t.player_1_id === player.id ||
													t.player_2_id === player.id)
									  )
							)
							.map((player) => (
								<option value={player.id} key={player.id}>
									{player.name}
								</option>
							))}
					</select>
				</>
			)}
			<button
				className={`w-full bg-${theme}-primaryColor text-white text-sm font-gothamBold px-4 py-2 rounded-md mt-2`}
				onClick={handleSubmit}
				disabled={submitting}
			>
				{submitting ? "Creando equipo..." : "Crear equipo"}
			</button>
		</form>
	);
}
