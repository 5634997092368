import React, { useState } from "react";
import { endMatch } from "../firebase/client";
import { useSelector } from "react-redux";

export default function EndMatch({ match }) {
	const [endMatchData, setEndMatchData] = useState({
		scores: {
			set_1: {
				games_team_1: 0,
				games_team_2: 0,
				tiebreak: false,
				tiebreak_team_1: 0,
				tiebreak_team_2: 0,
			},
			set_2: {
				games_team_1: 0,
				games_team_2: 0,
				tiebreak: false,
				tiebreak_team_1: 0,
				tiebreak_team_2: 0,
			},
			set_3: {
				games_team_1: 0,
				games_team_2: 0,
				tiebreak: false,
				tiebreak_team_1: 0,
				tiebreak_team_2: 0,
			},
			set_4: {
				games_team_1: 0,
				games_team_2: 0,
				tiebreak: false,
				tiebreak_team_1: 0,
				tiebreak_team_2: 0,
			},
			set_5: {
				games_team_1: 0,
				games_team_2: 0,
				tiebreak: false,
				tiebreak_team_1: 0,
				tiebreak_team_2: 0,
			},
		},
		winner_team_id: "",
	});
	const [submittingEndMatch, setSubmittingEndMatch] = useState(false);
	const theme = useSelector((state) => state.theme);

	const handleEndMatch = (e) => {
		e.preventDefault();
		if (submittingEndMatch) return;
		setSubmittingEndMatch(true);
		if (
			(endMatchData.scores.set_1.games_team_1 === 0 &&
				endMatchData.scores.set_1.games_team_2 === 0) ||
			(endMatchData.scores.set_2.games_team_1 === 0 &&
				endMatchData.scores.set_2.games_team_2 === 0)
		) {
			alert("Completa todos los campos.");
			setSubmittingEndMatch(false);
			return;
		}
		if (endMatchData.winner_team_id === "") {
			alert("Selecciona un ganador.");
			setSubmittingEndMatch(false);
			return;
		}

		endMatch({
			...match,
			scores: endMatchData.scores,
			winner_team_id: endMatchData.winner_team_id,
		}).then(() => {
			alert("Partido finalizado correctamente.");
			setSubmittingEndMatch(false);
			window.location.reload();
		});
	};

	return (
		<>
			<div
				key={match.id}
				className={`w-full flex flex-col items-start justify-center gap-2 border-2 rounded-md p-4 border-${theme}-secondaryColor`}
			>
				<div>
					<h3
						className={`text-${theme}-primaryColor text-base font-gothamBold`}
					>
						{match.category === "singles" &&
							match.team_1.player_1.name + " vs " + match.team_2.player_1.name}
						{match.category === "doubles" && (
							<>
								{match.team_1.player_1.name +
									" & " +
									match.team_1.player_2.name}
								<br />
								{match.team_2.player_1.name +
									" & " +
									match.team_2.player_2.name}
							</>
						)}
					</h3>
					<h3 className={`text-${theme}-primaryColor text-sm`}>
						{new Date(match.start_date).toLocaleString()} - {match.court.name}
					</h3>
				</div>
				<form className={`w-full flex flex-col items-start`}>
					{[...Array(match.sets)]
						.map((_, i) => i + 1)
						.map((set) => (
							<div
								key={set}
								className={`w-full flex flex-col items-start mb-2`}
							>
								<h3
									className={`text-${theme}-primaryColor text-base font-gothamBold mt-2`}
								>
									Set {set}:
								</h3>
								<label
									htmlFor={`set_${set}_team_1`}
									className={`text-${theme}-primaryColor text-sm`}
								>
									{match.category === "singles"
										? match.team_1.player_1.name
										: match.team_1.player_1.name +
										  " & " +
										  match.team_1.player_2.name}
									:
								</label>
								<input
									type="number"
									id={`set_${set}_team_1`}
									className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
									onChange={(e) =>
										setEndMatchData({
											...endMatchData,
											scores: {
												...endMatchData.scores,
												[`set_${set}`]: {
													...endMatchData.scores[`set_${set}`],
													games_team_1: e.target.value,
												},
											},
										})
									}
									value={endMatchData.scores[`set_${set}`]?.games_team_1}
								/>
								<label
									htmlFor={`set_${set}_team_2`}
									className={`text-${theme}-primaryColor text-sm mt-2`}
								>
									{match.category === "singles"
										? match.team_2.player_1.name
										: match.team_2.player_1.name +
										  " & " +
										  match.team_2.player_2.name}
								</label>
								<input
									type="number"
									id={`set_${set}_team_2`}
									className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
									onChange={(e) =>
										setEndMatchData({
											...endMatchData,
											scores: {
												...endMatchData.scores,
												[`set_${set}`]: {
													...endMatchData.scores[`set_${set}`],
													games_team_2: e.target.value,
												},
											},
										})
									}
									value={endMatchData.scores[`set_${set}`]?.games_team_2}
								/>

								<label
									htmlFor={`set_${set}_tiebreak`}
									className={`text-${theme}-primaryColor text-sm mt-2`}
								>
									Tiebreak
								</label>
								<input
									type="checkbox"
									id={`set_${set}_tiebreak`}
									className={`border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
									onChange={(e) =>
										setEndMatchData({
											...endMatchData,
											scores: {
												...endMatchData.scores,
												[`set_${set}`]: {
													...endMatchData.scores[`set_${set}`],
													tiebreak: e.target.checked,
												},
											},
										})
									}
									checked={endMatchData.scores[`set_${set}`]?.tiebreak}
								/>
								{endMatchData.scores[`set_${set}`]?.tiebreak && (
									<>
										<label
											htmlFor={`set_${set}_tiebreak_team_1`}
											className={`text-${theme}-primaryColor text-sm mt-2`}
										>
											Tiebreak{" "}
											{match.category === "singles"
												? match.team_1.player_1.name
												: match.team_1.player_1.name +
												  " & " +
												  match.team_1.player_2.name}
										</label>
										<input
											type="number"
											id={`set_${set}_tiebreak_team_1`}
											className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
											onChange={(e) =>
												setEndMatchData({
													...endMatchData,
													scores: {
														...endMatchData.scores,
														[`set_${set}`]: {
															...endMatchData.scores[`set_${set}`],
															tiebreak_team_1: e.target.value,
														},
													},
												})
											}
											value={endMatchData.scores[`set_${set}`]?.tiebreak_team_1}
										/>

										<label
											htmlFor={`set_${set}_tiebreak_team_2`}
											className={`text-${theme}-primaryColor text-sm mt-2`}
										>
											Tiebreak{" "}
											{match.category === "singles"
												? match.team_2.player_1.name
												: match.team_2.player_1.name +
												  " & " +
												  match.team_2.player_2.name}
										</label>
										<input
											type="number"
											id={`set_${set}_tiebreak_team_2`}
											className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1 h-7`}
											onChange={(e) =>
												setEndMatchData({
													...endMatchData,
													scores: {
														...endMatchData.scores,
														[`set_${set}`]: {
															...endMatchData.scores[`set_${set}`],
															tiebreak_team_2: e.target.value,
														},
													},
												})
											}
											value={endMatchData.scores[`set_${set}`]?.tiebreak_team_2}
										/>
									</>
								)}
							</div>
						))}
					<label
						htmlFor="winner_team_id"
						className={`text-${theme}-primaryColor text-sm font-gothamBold mt-2`}
					>
						Equipo ganador
					</label>
					<select
						id="winner_team_id"
						className={`w-full border border-${theme}-primaryColor rounded-md px-2 py-1`}
						onChange={(e) =>
							setEndMatchData({
								...endMatchData,
								winner_team_id: e.target.value,
							})
						}
					>
						<option value="" disabled selected>
							Selecciona un equipo
						</option>
						<option value={match.team_1.id}>
							{match.team_1.player_1.name}
							{match.category === "doubles" &&
								` & ${match.team_1.player_2.name}`}
						</option>
						<option value={match.team_2.id}>
							{match.team_2.player_1.name}
							{match.category === "doubles" &&
								` & ${match.team_2.player_2.name}`}
						</option>
					</select>
					<button
						className={`w-full bg-${theme}-primaryColor text-white text-sm font-gothamBold px-4 py-2 rounded-md mt-4`}
						onClick={handleEndMatch}
						disabled={submittingEndMatch}
					>
						{submittingEndMatch ? "Finalizando..." : "Finalizar"}
					</button>
				</form>
			</div>
		</>
	);
}
