import { useSelector } from "react-redux";
import UpcomingMatch from "./UpcomingMatch";

export default function UpcomingMatches({ matches }) {
	const theme = useSelector((state) => state.theme);
	return (
		<div className={`w-full max-w-[800px] px-2`}>
			<h1
				className={`text-center font-bold text-lg max-lg:text-base text-${theme}-primaryColor mb-2`}
			>
				Próximos Partidos
			</h1>
			{matches.length === 0 ? (
				<p className={`text-center text-${theme}-primaryColor text-sm`}>
					No hay partidos programados.
				</p>
			) : (
				<div className={`flex flex-col gap-2`}>
					{matches.map((match) => (
						<div key={match.id}>
							<UpcomingMatch match={match} />
						</div>
					))}
				</div>
			)}
		</div>
	);
}
