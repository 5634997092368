import { useEffect, useState } from "react";
import MatchResult from "../components/MatchResult";
import { UpcomingMatch, Loader } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { getMatches, getPlayers, getTeams } from "../redux/actions/actions";

export default function Matches() {
	const dispatch = useDispatch();
	const matches = useSelector((state) => state.matches);
	const [filteredMatches, setFilteredMatches] = useState([]);
	const players = useSelector((state) => state.players);
	const teams = useSelector((state) => state.teams);
	const [category, setCategory] = useState("all");
	const [team_1, setTeam_1] = useState("all");
	const [team_2, setTeam_2] = useState("all");
	const [loading, setLoading] = useState(true);
	const theme = useSelector((state) => state.theme);
	useEffect(() => {
		if (matches.length === 0) dispatch(getMatches());
		if (players.length === 0) dispatch(getPlayers());
		if (teams.length === 0) dispatch(getTeams());
	}, [dispatch, matches, players, teams]);

	useEffect(() => {
		setTeam_1("all");
		setTeam_2("all");
	}, [category]);

	useEffect(() => {
		setFilteredMatches(
			matches
				.filter((match) => match.ended === true)
				.filter((match) =>
					category === "all"
						? true
						: category === "singles"
						? match.category === "singles"
						: match.category === "doubles"
				)
				.filter((match) =>
					team_1 === "all"
						? true
						: category === "singles"
						? match.team_1.player_1.id === team_1 ||
						  match.team_2.player_1.id === team_1
						: match.team_1.id === team_1 || match.team_2.id === team_1
				)
				.filter((match) =>
					team_2 === "all"
						? true
						: category === "singles"
						? match.team_1.player_1.id === team_2 ||
						  match.team_2.player_1.id === team_2
						: match.team_1.id === team_2 || match.team_2.id === team_2
				)
		);
	}, [matches, category, team_1, team_2]);

	useEffect(() => {
		if (
			matches.length > 0 &&
			players.length > 0 &&
			teams.length > 0 &&
			filteredMatches.length > 0
		) {
			setLoading(false);
		}
	}, [matches, players, teams, filteredMatches]);

	return (
		<>
			{loading ? (
				<div className={`flex justify-center items-center h-[60svh]`}>
					<Loader className={`w-10`} />
				</div>
			) : (
				<div
					className={`flex flex-col max-lg:gap-3 gap-6 mt-2 items-center justify-center`}
				>
					<div className={`w-full max-w-[800px] px-2`}>
						<h1
							className={`text-center font-bold text-lg text-${theme}-primaryColor mb-2 max-lg:text-base`}
						>
							Próximos Partidos
						</h1>
						<div className={`flex flex-col gap-2`}>
							{matches.filter((match) => match.ended === false).length === 0 ? (
								<p className={`text-center text-${theme}-primaryColor text-sm`}>
									No hay partidos programados.
								</p>
							) : (
								<div className={`flex flex-col gap-2`}>
									{matches
										.filter((match) => match.ended === false)
										.map((match) => (
											<div key={match.id}>
												<UpcomingMatch match={match} />
											</div>
										))}
								</div>
							)}
						</div>
					</div>
					<div className={`w-full max-w-[800px] px-2`}>
						<h1
							className={`text-center font-bold text-lg text-${theme}-primaryColor mb-2 max-lg:text-base`}
						>
							Partidos pasados
						</h1>
						<div className={`flex flex-row mb-2 justify-between`}>
							<div>
								<select
									className={`border border-${theme}-secondaryColor rounded-md px-2 py-1`}
									onChange={(e) => setCategory(e.target.value)}
								>
									<option value="all">Todos</option>
									<option value="singles">Singles</option>
									<option value="doubles">Dobles</option>
								</select>
							</div>
							<div
								className={`flex flex-row gap-2 justify-center items-center`}
							>
								<select
									className={`border border-${theme}-secondaryColor rounded-md px-2 py-1 max-w-[300px] max-lg:max-w-[100px]`}
									onChange={(e) => setTeam_1(e.target.value)}
								>
									<option value="all">Todos</option>
									{category === "singles"
										? players
												.filter((player) => player.id !== team_2)
												.map((player) => (
													<option key={player.id} value={player.id}>
														{player.name}
													</option>
												))
										: category === "doubles"
										? teams

												.filter((team) => team.category === "doubles")
												.filter((team) => team.id !== team_2)
												.filter(
													(team) =>
														team.player_1.id !==
															teams.find((team) => team.id === team_2)?.player_1
																.id &&
														team.player_2.id !==
															teams.find((team) => team.id === team_2)?.player_1
																.id &&
														team.player_1.id !==
															teams.find((team) => team.id === team_2)?.player_2
																.id &&
														team.player_2.id !==
															teams.find((team) => team.id === team_2)?.player_2
												)
												.map((team) => (
													<option key={team.id} value={team.id}>
														{team.player_1?.name} & {team.player_2?.name}
													</option>
												))
										: null}
								</select>
								<p className={`text-${theme}-primaryColor text-xs`}>VS</p>
								<select
									className={`border border-${theme}-secondaryColor rounded-md px-2 py-1 max-w-[300px] max-lg:max-w-[100px]`}
									onChange={(e) => setTeam_2(e.target.value)}
								>
									<option value="all">Todos</option>
									{category === "singles"
										? players
												.filter((player) => player.id !== team_1)
												.map((player) => (
													<option key={player.id} value={player.id}>
														{player.name}
													</option>
												))
										: category === "doubles"
										? teams
												.filter((team) => team.category === "doubles")
												.filter((team) => team.id !== team_1)
												.filter(
													(team) =>
														team.player_1.id !==
															teams.find((team) => team.id === team_1)?.player_1
																.id &&
														team.player_2.id !==
															teams.find((team) => team.id === team_1)?.player_1
																.id &&
														team.player_1.id !==
															teams.find((team) => team.id === team_1)?.player_2
																.id &&
														team.player_2.id !==
															teams.find((team) => team.id === team_1)?.player_2
												)
												.map((team) => (
													<option key={team.id} value={team.id}>
														{team.player_1?.name} & {team.player_2?.name}
													</option>
												))
										: null}
								</select>
							</div>
						</div>

						{filteredMatches.length === 0 ? (
							<p
								className={`text-center text-${theme}-primaryColor text-sm mt-2`}
							>
								No hay partidos pasados con los filtros seleccionados.
							</p>
						) : (
							<div className={`flex flex-col gap-2`}>
								{filteredMatches.map((match) => (
									<div key={match.id}>
										<MatchResult match={match} />
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}
