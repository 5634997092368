import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function PlayerCard({ player }) {
	const theme = useSelector((state) => state.theme);
	return (
		<Link
			className={`flex flex-col items-center justify-center rounded-md p-4 border-2 border-${theme}-secondaryColor h-60`}
			to={`/players/${player?.id}`}
		>
			<img
				src={player?.avatar}
				alt={player?.name}
				className={`border-2 border-${theme}-primaryColor w-24 h-24 rounded-full object-cover`}
			/>
			<h1
				className={`text-${theme}-primaryColor font-bold text-base mt-2 uppercase max-lg:text-sm text-center`}
			>
				{player?.name}
			</h1>
			<p
				className={`text-${theme}-primaryColor text-xs font-semibold max-lg:text-xs`}
			>
				RANKING GLOBAL: #{player?.ranking_position}
			</p>
			<p
				className={`text-${theme}-primaryColor text-xs font-semibold max-lg:text-xs`}
			>
				RANKING SINGLES: #{player?.ranking_position_singles}
			</p>
		</Link>
	);
}
